import React, { useEffect, useState } from 'react';
import { useForm, useStep } from 'react-hooks-helper';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';

import {
  PincodeStep,
  RecipientCompanyStep,
  PositionStep,
  EmployeeNameStep,
  EmployeeEmailStep,
  EmployeePhoneStep,
  AddressStep,
  CityStep,
  StateStep,
  ZipCodeStep,
  GamesStep,
  RecipientNameStep,
  RecipientEmailStep,
  RecipientPhoneStep,
  JobFunctionStep,
  ReqTypeExtStep,
  ParkingStep,
  SatTruckStep,
  NotesStep,
  ApprovedStep,
  ReviewForm,
  SubmissionForm,
} from './Steps';

const ALL_GAMES = gql`
  {
    allGames {
      id
      alias
      label
      abbr
      date
      activeCred
    }
  }
`;
const steps = [
  { id: 'pincodeStep' },
  { id: 'recipientCompanyStep' },
  { id: 'positionStep' },
  { id: 'employeeNameStep' },
  { id: 'employeeEmailStep' },
  { id: 'employeePhoneStep' },
  { id: 'addressStep' },
  { id: 'cityStep' },
  { id: 'stateStep' },
  { id: 'zipCodeStep' },
  { id: 'gamesStep' },
  { id: 'recipientNameStep' },
  { id: 'recipientEmailStep' },
  { id: 'recipientPhoneStep' },
  { id: 'jobFunctionStep' },
  { id: 'reqTypeExtStep' },
  { id: 'parkingStep' },
  { id: 'satTruckStep' },
  { id: 'notesStep' },
  { id: 'approvedStep' },
  { id: 'ReviewForm' },
  { id: 'SubmissionForm' },
];
const defaultData = {
  pincode: '',
  recipientCompany: '',
  position: '',
  employeeName: '',
  employeePhone: '',
  employeeEmail: '',
  games: {},
  reqType: '',
  customReqType: '',
  numberOfCreds: '',
  notes: '',
  reasonForRequest: '',
  approved: '',
  recipientName: '',
  delivery: '',
  recipientEmail: '',
  recipientPhone: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
  jobFunction: '',
  reqTypeExt: '',
  parking: '',
  satTruck: '',
};
const defaultDataFilled = {
  pincode: 'LionsCred20',
  recipientEmail: 'brian@email.com',
  position: 'sportsEditor',
  employeeName: 'John Doe',
  employeePhone: '(111) 111 - 1111',
  employeeEmail: 'john@email.com',
  games: {
    aug2020: true,
  },
  reqType: 'operations',
  customReqType: '',
  numberOfCreds: '3',
  notes: 'This is an example note.',
  reasonForRequest: 'This is an example reason.',
  approved: 'approved',
  recipientName: 'John, Brian',
  recipientCompany: 'Channel1Media',
  delivery: 'mobile',
  recipientPhone: '(111) 111 - 1111',
  address: '4274 Sundown Lane',
  city: 'Austin',
  state: 'TX',
  zipCode: '78746',
  jobFunction: 'newspaperReporter',
  reqTypeExt: 'media',
  parking: 'yesParking',
  satTruck: 'noTruck',
};

const MultiStepFormBase = () => {
  const [allGames, setAllGames] = useState();
  const [fetchAllGames] = useLazyQuery(ALL_GAMES, {
    fetchPolicy: 'network-only',
    onCompleted: async res => {
      const _allGames = await res?.allGames.filter(game => game.activeCred);

      setAllGames([
        {
          alias: 'all2020',
          label: 'All 2020 Home Games',
          abbr: '',
        },
        ..._allGames,
      ]);
    },
    onError: error => {
      console.log('Error', error);
    },
  });
  const [formData, setForm] = useForm(
    process.env.NODE_ENV === 'development' ? defaultDataFilled : defaultData,
  );
  const { step, navigation } = useStep({ initialStep: 0, steps });

  const { id } = step;

  const props = { formData, setForm, navigation, step };
  useEffect(() => {
    if (!allGames) fetchAllGames();
  }, [formData, allGames]);
  switch (id) {
    case 'pincodeStep':
      return <PincodeStep {...props} />;
    case 'recipientCompanyStep':
      return <RecipientCompanyStep {...props} />;
    case 'positionStep':
      return <PositionStep {...props} />;
    case 'employeeNameStep':
      return <EmployeeNameStep {...props} />;
    case 'employeeEmailStep':
      return <EmployeeEmailStep {...props} />;
    case 'employeePhoneStep':
      return <EmployeePhoneStep {...props} />;
    case 'addressStep':
      return <AddressStep {...props} />;
    case 'cityStep':
      return <CityStep {...props} />;
    case 'stateStep':
      return <StateStep {...props} />;
    case 'zipCodeStep':
      return <ZipCodeStep {...props} />;
    case 'gamesStep':
      return <GamesStep {...props} allGames={allGames} />;
    case 'recipientNameStep':
      return <RecipientNameStep {...props} />;
    case 'recipientEmailStep':
      return <RecipientEmailStep {...props} />;
    case 'recipientPhoneStep':
      return <RecipientPhoneStep {...props} />;
    case 'jobFunctionStep':
      return <JobFunctionStep {...props} />;
    case 'reqTypeExtStep':
      return <ReqTypeExtStep {...props} />;
    case 'parkingStep':
      return <ParkingStep {...props} />;
    case 'satTruckStep':
      return <SatTruckStep {...props} />;
    case 'notesStep':
      return <NotesStep {...props} />;
    case 'approvedStep':
      return <ApprovedStep {...props} />;
    case 'ReviewForm':
      return <ReviewForm {...props} allGames={allGames} />;
    case 'SubmissionForm':
      return <SubmissionForm {...props} />;
    default:
      return null;
  }
};

export default MultiStepFormBase;
