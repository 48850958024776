export const credsTypeListExt = [
  {
    id: 'fieldPhoto',
    label: 'Field Photo',
  },
  {
    id: 'media',
    label: 'Media (Press Box)',
  },
  {
    id: 'tVCamera',
    label: 'TV Camera',
  },
];
