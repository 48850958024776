export const MARGARET =
  process.env.NODE_ENV === 'development'
    ? '5d2dde4c928610296b26ffad'
    : '5d1e0e33f996013dc30db04a';
export const SOLOMON =
  process.env.NODE_ENV === 'development'
    ? '5d30a59235072a08fdae2870'
    : '5d1e12a4f996013dc30db052';
export const KRISTEN =
  process.env.NODE_ENV === 'development'
    ? '5d3b414797028e0335d00891'
    : '5d1e1221f996013dc30db050';
export const TODD = '5d1e1261f996013dc30db051';
export const EAMONN = '5d1e12e1f996013dc30db053';
export const MARK = '5d2cd797cce95411878c12a0';
export const REGIS = '5d2cd823cce95411878c12a2';
export const BILL = '5d2cd7e0cce95411878c12a1';
export const EMILY = '5d2cec073c388a17fd1e5483';
export const RYAN = '5d2cec633c388a17fd1e5484';
export const MEGAN = '5d2cef823c388a17fd1e5486';
export const ADMIN = '5d3f2d8997a33e164e3fcc1f';
