export const credsTypeList = [
  { id: 'fieldContestant', label: 'Field Contestant' },
  {
    id: 'fieldPhoto',
    label: 'Field Photo',
  },
  {
    id: 'gameServices',
    label: 'Game Services',
  },
  {
    id: 'media',
    label: 'Media',
  },
  {
    id: 'official',
    label: 'Official',
  },
  {
    id: 'operations',
    label: 'Operations',
  },
  {
    id: 'pregameSideline',
    label: 'Pregame Sideline',
  },
  {
    id: 'tVCamera',
    label: 'TV Camera',
  },
  {
    id: 'fieldPromotions',
    label: 'Field Promotions',
  },
];

export const credsTypeZoneList = [
  { id: 'zone1', label: 'Zone 1' },
  { id: 'zone2', label: 'Zone 2' },
  { id: 'zone3', label: 'Zone 3' },
  { id: 'zone4', label: 'Zone 4' },
];
