import React, { useState, useEffect } from 'react';

import {
  Input,
  Radio,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

import styles from '../../../Forms.scss';

const NotesStep = ({ setForm, formData, navigation, step }) => {
  const { notes } = formData;
  const { next, previous } = navigation;
  const [isValid, setIsValid] = useState(true);

  function keyDownHandler(e) {
    e.key === 'Enter' && isValid ? next() : null;
    e.key === 'Backspace' && step.id !== 'stepOne' ? previous() : null;
  }

  KeyboardNav(step, isValid, navigation);

  useEffect(() => {}, [formData, isValid]);
  return (
    <>
      <h4>
        <span>19</span> Special Request/Comments (if necessary):
      </h4>
      <Input
        name="notes"
        placeholder="Type your answer here …"
        type="textarea"
        value={notes}
        onChange={setForm}
      />

      <Navigation navigation={navigation} isValid={isValid} />
    </>
  );
};

export default NotesStep;
