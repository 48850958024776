import React from 'react';
import ReactDOM from 'react-dom';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';

import App from './App.js';

const client = new ApolloClient({
  request: operation => {
    const jwtAuth = JSON.parse(localStorage.getItem('jwtAuth'));

    operation.setContext({
      headers: {
        Authorization: jwtAuth?.token ? `Bearer ${jwtAuth.token}` : '',
      },
    });
  },
  uri:
    process.env.NODE_ENV === 'development'
      ? `http://localhost:5000/graphql`
      : `https://api.request.lions.vipfanportal.com/graphql`,
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('app'),
);
