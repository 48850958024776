export const ticketsTypeList = [
  { id: 'charityCommunityRelations', label: 'Charity/Community Relations' },
  {
    id: 'formerEmployees',
    label: 'Former Employees',
  },
  {
    id: 'media',
    label: 'Media',
  },
  {
    id: 'gamedayWorkers',
    label: 'Gameday Workers',
  },
  {
    id: 'localDignitariesVIPs',
    label: 'Local Dignitaries/VIPs',
  },
  {
    id: 'mediaGuests',
    label: 'Media Guests',
  },
  {
    id: 'nflEmployees',
    label: 'NFL Employees',
  },
  {
    id: 'promotions',
    label: 'Promotions',
  },
  {
    id: 'radioTvTrade',
    label: 'Radio/TV Trade',
  },
  {
    id: 'corporatePartnershipObligation',
    label: 'Corporate Partnership Obligation',
  },
  {
    id: 'corporatePartnershipTrade',
    label: 'Corporate Partnership Trade',
  },
  {
    id: 'suiteTrade',
    label: 'Suite Trade',
  },
  {
    id: 'stadiumLeaseRequirement',
    label: 'Stadium Lease Requirement',
  },
  {
    id: 'ticketingProspect',
    label: 'Ticketing Prospect',
  },
  {
    id: 'vendorContractor',
    label: 'Vendor/Contractor',
  },
  {
    id: 'visitingTeam',
    label: 'Visiting Team',
  },
  {
    id: 'youthGroup',
    label: 'Youth Group',
  },
];
