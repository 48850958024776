import React, { useState, useEffect, useRef } from 'react';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import cx from 'classnames';
import Loader from 'react-loader-spinner';
import Game from './Game';
import { AuthContext } from '../../../contexts/AuthContext';
import * as ROUTES from '../../../constants/routes';

import styles from './Games.scss';

const ALL_GAMES = gql`
  query AllGames {
    allGames {
      id
      alias
      label
      abbr
      date
      activeTicket
      activeCred
      activeParking
      ticketCounts {
        total
        pending
        approved
        fulfilled
      }
      credCounts {
        total
        pending
        approved
        fulfilled
      }
      parkingCounts {
        total
        pending
        approved
        fulfilled
      }
    }
  }
`;
const TOGGLE_ACTIVE = gql`
  mutation UpdateGameStatus($updateGameActiveInput: UpdateGameActiveInput!) {
    updateGameStatus(updateGameActiveInput: $updateGameActiveInput) {
      id
    }
  }
`;

const GamesPage = props => {
  const { history } = props;
  const auth = React.useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [allGames, setAllGames] = useState();

  const [allGamesFetch] = useLazyQuery(ALL_GAMES, {
    fetchPolicy: 'network-only',
    onCompleted: res => {
      setAllGames(res.allGames);
      setLoading(false);
    },
    onError: err => {
      console.log(err);
      setLoading(false);
    },
  });
  const [toggleActive] = useMutation(TOGGLE_ACTIVE, {
    onError: error => {
      console.log(error);
    },
  });
  const fetchApiHandler = () => {
    setLoading(true);
    allGamesFetch();
  };

  useEffect(() => {
    if (!auth.token) {
      const jwtAuth = JSON.parse(localStorage.getItem('jwtAuth'));
      !jwtAuth ? history.push(ROUTES.LOGIN) : null;
    }
    if (!allGames) fetchApiHandler();
  }, [auth, loading, allGames]);

  return (
    <div className={styles.body}>
      <h1>Tickets</h1>
      {loading ? (
        <div className={styles.loader}>
          <Loader type="Grid" color="#1192e2" height={40} width={40} />
        </div>
      ) : (
        <>
          {allGames?.map(game => (
            <Game key={game.id} game={game} toggleActive={toggleActive} />
          ))}
        </>
      )}
    </div>
  );
};

export default GamesPage;
