import React, { useState, useEffect } from 'react';
import { AuthContext } from '../../../contexts/AuthContext';
import { FetchApiContext } from '../../../contexts/FetchApiContext';
import { pageContext } from '../../../contexts/PageContext';
import * as ROUTES from '../../../constants/routes';
import * as USERS from '../../../constants/users';
import { fetchUrl } from '../../../../helpers/fetchUrl';
import { exportCsv } from '../../../../helpers/exportCsv';
import FilterNav from '../../../components/FilterNav/FilterNav';
import { BoxRow, BoxContainer } from '../../../components/shared';
import Dropdown from '../../../components/shared/Form/Dropdown';

import styles from './Credentials.scss';

const CredentialsExtPage = props => {
  const { auth, history } = props;
  const [loading, setLoading] = useState(false);
  const [exportUrl, setExportUrl] = useState();
  const [exportPendingUrl, setExportPendingUrl] = useState();
  const [exportApprovedUrl, setExportApprovedUrl] = useState();
  const [exportFulfilledUrl, setExportFulfilledUrl] = useState();
  const [credsList, setCredsList] = useState();
  const [credsStatus, setCredsStatus] = useState();
  const [fetchApi, setFetchApi] = useState(true);
  const [filterStatus, setFilterStatus] = useState({
    credType: 'allCredTypes',
    gameDate: '',
  });
  const createStatusArrays = async creds => {
    setCredsList(creds);

    const pendingItems = [];
    const approvedItems = [];
    const fulfilledItems = [];
    let filteredCreds = [];

    switch (filterStatus.credType) {
      case 'allCredTypes':
        filteredCreds = await creds;
        break;
      case 'fieldPhoto':
        filteredCreds = await credsList.filter(item =>
          item.reqTypeExt.includes('fieldPhoto'),
        );
        break;
      case 'media':
        filteredCreds = await credsList.filter(item =>
          item.reqTypeExt.includes('media'),
        );
        break;
      case 'tVCamera':
        filteredCreds = await credsList.filter(item =>
          item.reqTypeExt.includes('tVCamera'),
        );
        break;
      case 'customCredType':
        filteredCreds = await credsList.filter(
          item => item.customReqType !== '',
        );
        break;
      default:
        console.log('No creds!');
    }

    switch (filterStatus.gameDate) {
      case '':
        filteredCreds = await filteredCreds;
        break;

      case 'NYJ':
        filteredCreds = filteredCreds.filter(item =>
          item.games[0].split(',').includes('NYJ'),
        );
        break;

      case 'BUF':
        filteredCreds = filteredCreds.filter(item =>
          item.games[0].split(',').includes('BUF'),
        );
        break;

      case 'CHI':
        filteredCreds = filteredCreds.filter(item =>
          item.games[0].split(',').includes('CHI'),
        );
        break;

      case 'NO':
        filteredCreds = filteredCreds.filter(item =>
          item.games[0].split(',').includes('NO'),
        );
        break;

      case 'IND':
        filteredCreds = filteredCreds.filter(item =>
          item.games[0].split(',').includes('IND'),
        );
        break;

      case 'WSH':
        filteredCreds = filteredCreds.filter(item =>
          item.games[0].split(',').includes('WSH'),
        );
        break;

      case 'HOU':
        filteredCreds = filteredCreds.filter(item =>
          item.games[0].split(',').includes('HOU'),
        );
        break;

      case 'GB':
        filteredCreds = filteredCreds.filter(item =>
          item.games[0].split(',').includes('GB'),
        );
        break;

      case 'TB':
        filteredCreds = filteredCreds.filter(item =>
          item.games[0].split(',').includes('TB'),
        );
        break;

      case 'MIN':
        filteredCreds = filteredCreds.filter(item =>
          item.games[0].split(',').includes('MIN'),
        );
        break;

      default:
        console.log('No tickets!');
    }

    await filteredCreds.map(item => {
      const { status } = item;
      switch (status) {
        case 'pending':
          pendingItems.push(item);
          break;
        case 'approved':
          approvedItems.push(item);
          break;
        case 'fulfilled':
          fulfilledItems.push(item);
          break;
        default:
          console.log('No status found!');
      }
      return false;
    });

    setCredsStatus({
      pending: pendingItems,
      approved: approvedItems,
      fulfilled: fulfilledItems,
    });
  };
  const filterArray = value => {
    setFilterStatus({
      ...filterStatus,
      credType: value,
    });
    fetchApiHandler();
  };

  const fetchData = () => {
    setLoading(true);
    const requestBody = {
      query: `
      query{
        creds {
          _id
          employeeName
          employeePhone
          employeeEmail
          games
          customReqType
          numberOfCreds
          notes
          reasonForRequest
          recipientName
          recipientCompany
          delivery
          recipientEmail
          recipientPhone
          dateRequested
          status
          confirmationId{
            _id
          }
          fulfillmentId{
            _id
            number
            zone
          }
          position
          address
          city
          state
          zipCode
          jobFunction
          reqTypeExt
          parking
          satTruck
          credType
        }
      }
      `,
    };

    fetch(fetchUrl('graphql'), {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + auth.token,
      },
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(resData => {
        const { creds } = resData.data;
        setLoading(false);
        return creds;
      })
      .then(creds => {
        return creds.filter(item => item.credType);
      })
      .then(creds => {
        createStatusArrays(creds);
        setFetchApi(false);
      })
      .catch(err => {
        console.log(err);
      });
  };
  const fetchApiHandler = () => {
    setFetchApi(true);
    fetchData();
  };

  useEffect(() => {
    exportCsv('credsExt', credsList).then(res => setExportUrl(res));
    exportCsv('credsExt', credsList, 'pending').then(res =>
      setExportPendingUrl(res),
    );
    exportCsv('credsExt', credsList, 'approved').then(res =>
      setExportApprovedUrl(res),
    );
    exportCsv('credsExt', credsList, 'fulfilled').then(res =>
      setExportFulfilledUrl(res),
    );
    if (!auth.token) {
      const jwtAuth = JSON.parse(localStorage.getItem('jwtAuth'));
      !jwtAuth ? history.push(ROUTES.LOGIN) : null;
    }
    auth.token && fetchApi ? fetchData() : null;
  }, [auth, credsList, filterStatus]);

  return (
    <>
      <FilterNav
        setFilterStatus={setFilterStatus}
        filterStatus={filterStatus}
        fetchApiHandler={fetchApiHandler}
      />
      <div className={styles.body}>
        <div className={styles.filter}>
          <div className={styles.filter__type}>
            <div className={styles.filter__type__item}>
              <Dropdown
                onChange={item => filterArray(item.target.value)}
                page="credentialsExt"
              />
            </div>
          </div>
          <button
            className={styles.btn__filter}
            onClick={() => fetchApiHandler()}
          >
            Refresh
          </button>
          <a
            href={exportUrl}
            download={`credentials-ext-${new Date().getFullYear()}-${new Date().getMonth() +
              1}-${new Date().getDate()}-${new Date().getHours()}_${new Date().getMinutes()}.csv`}
          >
            <button className={styles.btn__filter}>Export</button>{' '}
          </a>
        </div>
        <pageContext.Provider value="credentials">
          <FetchApiContext.Provider value={fetchApiHandler}>
            {/* <button onClick={() => fetchApiHandler()}>Refresh</button> */}
            <>
              {(auth.userId === USERS.MEGAN ||
                auth.userId === USERS.EAMONN ||
                auth.userId === USERS.EMILY ||
                auth.userId === USERS.ADMIN ||
                auth.userId === USERS.SOLOMON) && (
                <BoxContainer title="Pending">
                  {credsStatus &&
                    credsStatus.pending.map(item => {
                      return <BoxRow item={item} key={item._id} />;
                    })}
                </BoxContainer>
              )}
              <BoxContainer title="Approved">
                {credsStatus &&
                  credsStatus.approved.map(item => {
                    return <BoxRow item={item} key={item._id} />;
                  })}
              </BoxContainer>
              <BoxContainer title="Fulfilled">
                {credsStatus &&
                  credsStatus.fulfilled.map(item => {
                    return <BoxRow item={item} key={item._id} />;
                  })}
              </BoxContainer>
            </>
          </FetchApiContext.Provider>
        </pageContext.Provider>
      </div>
    </>
  );
};

export default React.forwardRef((props, ref) => (
  <AuthContext.Consumer>
    {auth => <CredentialsExtPage {...props} auth={auth} ref={ref} />}
  </AuthContext.Consumer>
));
