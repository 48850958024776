import React, { useState, useEffect, createRef, useRef } from 'react';
import cx from 'classnames';

import {
  Input,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

const RecipientCompanyStep = ({ setForm, formData, navigation, step }) => {
  const { recipientCompany } = formData;
  const [isValid] = useState(true);

  KeyboardNav(step, isValid, navigation);

  useEffect(() => {}, [formData, isValid]);

  return (
    <>
      <h4>
        <span>11</span> Recipient Company (if applicable)
      </h4>
      <Input
        name="recipientCompany"
        placeholder="Type your answer here …"
        value={recipientCompany}
        onChange={setForm}
      />
      <Navigation navigation={navigation} isValid={isValid} />
    </>
  );
};

export default RecipientCompanyStep;
