export const jobFunctionList = [
  {
    id: 'newspaperBeatWriter',
    label: 'Newspaper: Beat Writer',
  },
  {
    id: 'newspaperColumnist',
    label: 'Newspaper: Columnist',
  },
  {
    id: 'newspaperReporter',
    label: 'Newspaper: Reporter',
  },
  {
    id: 'newspaperPhotographer',
    label: 'Newspaper: Photographer',
  },
  {
    id: 'magazineReporter',
    label: 'Magazine: Reporter',
  },
  {
    id: 'magazinePhotographer',
    label: 'Magazine: Photographer',
  },
  {
    id: 'tvTalent',
    label: 'TV: Talent',
  },
  {
    id: 'tvProducer',
    label: 'TV: Producer',
  },
  {
    id: 'tvTechnician',
    label: 'TV: Technician',
  },
  {
    id: 'RadioReporter',
    label: 'Radio: Reporter',
  },
  {
    id: 'RadioShowHost',
    label: 'Radio: Show Host',
  },
  {
    id: 'RadioStringer',
    label: 'Radio: Stringer',
  },
  {
    id: 'digitalReporter',
    label: 'Digital: Reporter',
  },
  {
    id: 'nflTeamScout',
    label: 'NFL: Team Scout',
  },
  {
    id: 'other',
    label: 'Other – Please specify in Special Request/Comments section.',
  },
];
