import React from 'react';

import { NavLink } from '../shared';
import { AuthContext } from '../../contexts/AuthContext';

import styles from './Header.scss';
import * as ROUTES from '../../constants/routes';
import * as USERS from '../../constants/users';

const Nav = props => {
  const { location } = props;
  const auth = React.useContext(AuthContext);

  const { role: userRoles } = auth;
  return (
    <nav className={styles.nav}>
      <ul>
        <li>
          <NavLink to={ROUTES.ADMIN_TICKETS} {...props}>
            Tickets
          </NavLink>
        </li>
        <li>
          <NavLink to={ROUTES.ADMIN_CREDENTIALS} {...props}>
            Credentials
          </NavLink>
        </li>
        {/* <li>
          <NavLink to={ROUTES.ADMIN_CREDENTIALS_EXT} {...props}>
            Ext Credentials
          </NavLink>
        </li> */}
        <li>
          <NavLink to={ROUTES.ADMIN_PARKING} {...props}>
            Parking
          </NavLink>
        </li>
        <li>
          {['1'].every(v => userRoles?.includes(v)) && (
            <NavLink to={ROUTES.ADMIN_GAMES} {...props}>
              Games
            </NavLink>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
