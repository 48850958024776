import React, { useState, useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import './styles/reset.css';
import styles from './App.scss';

import Home from './pages/Home';
import FormTickets from './pages/Forms/FormTickets';
import FormCreds from './pages/Forms/FormCreds';
import FormCredsExt from './pages/Forms/FormCredsExt';
import FormParkings from './pages/Forms/FormParkings';
import Admin from './pages/Admin';
import AdminTickets from './pages/Admin/Tickets';
import AdminCredentials from './pages/Admin/Credentials';
import AdminCredentialsExt from './pages/Admin/CredentialsExt';
import AdminParkings from './pages/Admin/Parkings';
import AdminGames from './pages/Admin/Games';
import Login from './pages/Auth/Login';
import { AuthContext } from './contexts/AuthContext';

import * as ROUTES from './constants/routes';

const App = () => {
  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [role, setRole] = useState(null);
  const [name, setName] = useState(null);

  const login = (currentToken, currentUserId, currentRole, currentName) => {
    setToken(currentToken);
    setUserId(currentUserId);
    setRole(currentRole);
    setName(currentName);

    localStorage.setItem(
      'jwtAuth',
      JSON.stringify({
        token: currentToken,
        userId: currentUserId,
        role: currentRole,
        name: currentName,
      }),
    );
  };

  const logout = () => {
    setToken(null);
    setUserId(null);
    localStorage.removeItem('jwtAuth');
  };

  useEffect(() => {
    const jwtAuth = JSON.parse(localStorage.getItem('jwtAuth'));
    if (jwtAuth) {
      login(jwtAuth.token, jwtAuth.userId, jwtAuth.role, jwtAuth.name);
    }
  }, []);

  return (
    <div className={styles.app}>
      <AuthContext.Provider
        value={{
          token,
          userId,
          role,
          name,
          login,
          logout,
        }}
      >
        <Router>
          <Switch>
            <Route exact path={ROUTES.HOME} component={Home} />
            <Route exact path={ROUTES.TICKETS} component={FormTickets} />
            <Route exact path={ROUTES.CREDENTIALS} component={FormCreds} />
            <Route exact path={ROUTES.PARKING} component={FormParkings} />
            <Route exact path={ROUTES.LOGIN} component={Login} />
            <Route
              exact
              path={ROUTES.CREDENTIALS_EXT}
              component={FormCredsExt}
            />
            {/* {!token && <Redirect to={ROUTES.LOGIN} />} */}
            {/* <Route exact path={ROUTES.ADMIN} component={Admin} /> */}
            <Redirect from={ROUTES.ADMIN} to={ROUTES.ADMIN_TICKETS} />
            <Route exact path={ROUTES.ADMIN_TICKETS} component={AdminTickets} />
            <Route exact path={ROUTES.ADMIN_GAMES} component={AdminGames} />
            {/* <Route
              exact
              path={ROUTES.ADMIN_CREDENTIALS_EXT}
              component={AdminCredentialsExt}
            /> */}
            <Route
              exact
              path={ROUTES.ADMIN_CREDENTIALS}
              component={AdminCredentials}
            />
            <Route
              exact
              path={ROUTES.ADMIN_PARKING}
              component={AdminParkings}
            />
          </Switch>
        </Router>
      </AuthContext.Provider>
    </div>
  );
};

export default hot(App);
