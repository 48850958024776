/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import cx from 'classnames';

import styles from './Games.scss';

const images = require.context('../../../images', true);

const Game = props => {
  const { game, toggleActive } = props;
  const [isCheckedTicket, setIsCheckedTicket] = useState(false);
  const [isCheckedCred, setIsCheckedCred] = useState(false);
  const [isCheckedParking, setIsCheckedParking] = useState(false);

  useEffect(() => {
    if (game.activeTicket) setIsCheckedTicket(true);
    if (game.activeCred) setIsCheckedCred(true);
    if (game.activeParking) setIsCheckedParking(true);
  }, []);
  const changeHandler = type => {
    switch (type) {
      case 'ticket':
        setIsCheckedTicket(!isCheckedTicket);
        toggleActive({
          variables: {
            updateGameActiveInput: {
              alias: game.alias,
              type,
              active: !isCheckedTicket,
            },
          },
        });
        break;
      case 'cred':
        setIsCheckedCred(!isCheckedCred);
        toggleActive({
          variables: {
            updateGameActiveInput: {
              alias: game.alias,
              type,
              active: !isCheckedCred,
            },
          },
        });
        break;
      case 'parking':
        setIsCheckedParking(!isCheckedParking);
        toggleActive({
          variables: {
            updateGameActiveInput: {
              alias: game.alias,
              type,
              active: !isCheckedParking,
            },
          },
        });
        break;

      default:
        break;
    }
  };
  return (
    <div className={styles.game}>
      <div className={styles.game__row}>
        <div className={styles.game__item}>
          <img src={images(`./${game.abbr}.png`)} alt="" />
        </div>
        <div className={cx(styles.game__item, styles.game__item__abs)}>
          {game.abbr}
        </div>
        <div className={styles.game__item}>{game.label}</div>
        <div className={styles.game__item}>
          {game.date.substring(0, 10).replace(/-/g, ' / ')}
        </div>
      </div>
      <div className={styles.game__row}>
        <div className={styles.game__item}>
          <p>Ticket Requests: </p>
        </div>
        <div className={styles.game__item}>
          Total:
          <span className={styles.statDigit}> {game?.ticketCounts?.total}</span>
        </div>
        <div className={styles.game__item}>
          Pending:{' '}
          <span className={styles.statDigit}>
            {game?.ticketCounts?.pending}
          </span>
        </div>
        <div className={styles.game__item}>
          Approved:{' '}
          <span className={styles.statDigit}>
            {game?.ticketCounts?.approved}
          </span>
        </div>
        <div className={styles.game__item}>
          Fulfilled:{' '}
          <span className={styles.statDigit}>
            {game?.ticketCounts?.fulfilled}
          </span>
        </div>
        <div className={styles.game__item}>
          <input
            type="checkbox"
            className={styles.switch_box_input}
            checked={isCheckedTicket}
            onChange={() => changeHandler('ticket')}
          />
        </div>
      </div>
      <div className={styles.game__row}>
        <div className={styles.game__item}>
          <p>Credential Requests: </p>
        </div>
        <div className={styles.game__item}>
          Total:
          <span className={styles.statDigit}> {game?.credCounts?.total}</span>
        </div>
        <div className={styles.game__item}>
          Pending:{' '}
          <span className={styles.statDigit}>{game?.credCounts?.pending}</span>
        </div>
        <div className={styles.game__item}>
          Approved:{' '}
          <span className={styles.statDigit}>{game?.credCounts?.approved}</span>
        </div>
        <div className={styles.game__item}>
          Fulfilled:{' '}
          <span className={styles.statDigit}>
            {game?.credCounts?.fulfilled}
          </span>
        </div>
        <div className={styles.game__item}>
          <input
            type="checkbox"
            className={styles.switch_box_input}
            checked={isCheckedCred}
            onChange={() => changeHandler('cred')}
          />
        </div>
      </div>
      <div className={styles.game__row}>
        <div className={styles.game__item}>
          <p>Parking Requests: </p>
        </div>
        <div className={styles.game__item}>
          Total:
          <span className={styles.statDigit}>{game?.parkingCounts?.total}</span>
        </div>
        <div className={styles.game__item}>
          Pending:{' '}
          <span className={styles.statDigit}>
            {game?.parkingCounts?.pending}
          </span>
        </div>
        <div className={styles.game__item}>
          Approved:{' '}
          <span className={styles.statDigit}>
            {game?.parkingCounts?.approved}
          </span>
        </div>
        <div className={styles.game__item}>
          Fulfilled:{' '}
          <span className={styles.statDigit}>
            {game?.parkingCounts?.fulfilled}
          </span>
        </div>
        <div className={styles.game__item}>
          <input
            type="checkbox"
            className={styles.switch_box_input}
            checked={isCheckedParking}
            onChange={() => changeHandler('parking')}
          />
        </div>
      </div>
    </div>
  );
};

export default Game;
