import React, { useState, useEffect, createRef, useRef } from 'react';
import cx from 'classnames';

import {
  Button,
  Input,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

import styles from '../../../Forms.scss';
import stylesForm from '../../../../../components/shared/Form/Form.scss';

const StepOneHalf = ({ setForm, formData, navigation, step }) => {
  const { address } = formData;
  const { next, previous } = navigation;
  const [isValid, setIsValid] = useState(true);
  const [emailError, setEmailError] = useState({ err: '' });

  KeyboardNav(step, isValid, navigation);

  return (
    <>
      <h4>
        <span>7</span> Address
      </h4>
      <Input
        name="address"
        placeholder="Enter your answer …"
        value={address}
        onChange={setForm}
      />

      <Navigation navigation={navigation} isValid={isValid} />
    </>
  );
};

export default StepOneHalf;
