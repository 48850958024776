export const parkingLotLocatoin = [
  {
    id: 'fordFieldParkingDeck',
    label: 'Ford Field Parking Deck',
  },
  {
    id: 'Lot4',
    label: 'Lot 4',
  },
  {
    id: 'Lot5',
    label: 'Lot 5',
  },
  {
    id: 'lionsTigersGarage',
    label: 'Lions/Tigers Garage',
  },
  {
    id: 'campusMartiusGarage',
    label: 'Campus Martius Garage',
  },
  {
    id: 'noPreference',
    label: 'No Preference',
  },
  {
    id: 'other',
    label: 'Other – Please specify in notes section',
  },
];
