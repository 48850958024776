import React, { useState, useEffect, createRef, useRef } from 'react';
import cx from 'classnames';

import {
  Button,
  Input,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

import styles from '../../../Forms.scss';
import stylesForm from '../../../../../components/shared/Form/Form.scss';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function validateEmail(email) {
  const validate = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validate.test(email);
}

const StepOne = ({ setForm, formData, navigation, step }) => {
  const { employeeEmail } = formData;
  const { next, previous } = navigation;
  const [isValid, setIsValid] = useState(false);
  const [emailError, setEmailError] = useState({ err: '' });

  const emailFeild = createRef();
  const prevEmail = usePrevious(employeeEmail);
  KeyboardNav(step, isValid, navigation);

  function emailOnFocus() {
    if (employeeEmail !== '' && !validateEmail(employeeEmail)) {
      setEmailError({ err: 'Email is not Valid!' });
    } else {
      setEmailError({ err: '' });
    }
  }

  useEffect(() => {
    if (employeeEmail !== '' && validateEmail(employeeEmail)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }

    if (
      prevEmail &&
      prevEmail.length > employeeEmail.length &&
      !validateEmail(employeeEmail)
    ) {
      setEmailError({ err: 'Email is not Valid!' });
    } else if (validateEmail(employeeEmail)) {
      setEmailError({ err: '' });
    }

    emailFeild.current.addEventListener('focusout', emailOnFocus);

    return () => {
      emailFeild.current
        ? emailFeild.current.removeEventListener('focusout', emailOnFocus)
        : null;
    };
  }, [formData, isValid]);

  return (
    <>
      <h4>
        <span>2</span> Your Email Address *
      </h4>
      <label
        htmlFor="employeeEmail"
        className={cx(
          stylesForm.input,
          emailError.err !== '' ? stylesForm.input__email_error : '',
        )}
      >
        <input
          type="text"
          placeholder=" "
          id="employeeEmail"
          name="employeeEmail"
          value={employeeEmail}
          onChange={setForm}
          ref={emailFeild}
          autoComplete="off"
        />
        <span className={stylesForm.label}>Type your email here …</span>
        <span className={stylesForm.border} />
        {emailError.err !== '' && (
          <span className={stylesForm.email__error}>{emailError.err}</span>
        )}
      </label>
      <Navigation navigation={navigation} isValid={isValid} prevDisable />
    </>
  );
};

export default StepOne;
