export const gamesList = [
  { id: 'all2020', label: 'All 2020 Home Games', abbr: '' },
  {
    id: 'aug2020',
    label: 'Thursday, August 20 vs. New York Jets (PRESEASON)',
    date: 'Aug 20',
    abbr: 'NYJ',
  },
  {
    id: 'sep2003',
    label: 'Thursday, September 03 vs. Buffalo Bills (PRESEASON)',
    date: 'Sep 03',
    abbr: 'BUF',
  },
  {
    id: 'sep2013',
    label: 'Sunday, September 13 vs. Chicago Bears',
    date: 'Sep 13',
    abbr: 'CHI',
  },
  {
    id: 'oct2004',
    label: 'Sunday, October 04 vs. New Orleans Saints',
    date: 'Oct 04',
    abbr: 'NO',
  },
  {
    id: 'nov2001',
    label: 'Sunday, November 01 vs. Indianapolis Colts',
    date: 'Nov 01',
    abbr: 'IND',
  },
  {
    id: 'nov2015',
    label: 'Sunday, November 15 vs. Washington Redskins',
    date: 'Nov 15',
    abbr: 'WSH',
  },
  {
    id: 'nov2026',
    label: 'Thursday, November 26 vs. Houston Texans',
    date: 'Nov 26',
    abbr: 'HOU',
  },
  {
    id: 'dec2013',
    label: 'Sunday, December 13 vs. Green Bay Packers',
    date: 'Dec 13',
    abbr: 'GB',
  },
  {
    id: 'dec2027',
    label: 'Sunday, December 27 vs. Tampa Bay Buccaneers',
    date: 'Dec 27',
    abbr: 'TB',
  },
  {
    id: 'jan2103',
    label: 'Sunday, January 03 vs. Minnesota Vikings',
    date: 'Jan 03',
    abbr: 'MIN',
  },
];
