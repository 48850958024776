import React, { useEffect, useState } from 'react';
import { useForm, useStep } from 'react-hooks-helper';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';

import {
  EmployeeNameStep,
  EmployeeEmailStep,
  GamesStep,
  ReqTypeStep,
  NumberOfTicketsStep,
  SeatingPreferenceStep,
  NotesStep,
  ReasonForRequestStep,
  AprrovedStep,
  RecipientNameStep,
  RecipientCompanyStep,
  DeliveryStep,
  RecipientEmailStep,
  RecipientPhoneStep,
  ReviewForm,
  SubmissionForm,
} from './Steps';

const steps = [
  { id: 'employeeNameStep' },
  { id: 'employeeEmailStep' },
  { id: 'gamesStep' },
  { id: 'reqTypeStep' },
  { id: 'numberOfTicketsStep' },
  { id: 'seatingPreferenceStep' },
  { id: 'notesStep' },
  { id: 'reasonForRequestStep' },
  { id: 'aprrovedStep' },
  { id: 'deliveryStep' },
  { id: 'recipientNameStep' },
  { id: 'recipientEmailStep' },
  { id: 'recipientPhoneStep' },
  { id: 'recipientCompanyStep' },
  { id: 'reviewForm' },
  { id: 'submissionForm' },
];
const defaultData = {
  employeeName: '',
  employeeEmail: '',
  games: {},
  reqType: '',
  customReqType: '',
  numberOfTickets: '',
  seatingPreference: '',
  notes: '',
  reasonForRequest: '',
  approved: '',
  recipientCompany: '',
  delivery: '',
  recipientName: '',
  recipientEmail: '',
  recipientPhone: '',
};
const defaultDataFilled = {
  employeeName: 'Jane Doe',
  employeeEmail: 'jane@email.com',
  games: {
    aug2020: true,
  },
  reqType: 'media',
  customReqType: '',
  numberOfTickets: '3',
  seatingPreference: 'clubLevel',
  notes: 'This is an example note.',
  reasonForRequest: 'This is an example reason.',
  approved: 'approved',
  delivery: 'mobile',
  recipientCompany: 'Channel1Media',
  recipientName: 'Jane, John',
  recipientEmail: 'jane@email.com',
  recipientPhone: '(111) 111 - 1111',
};
const ALL_GAMES = gql`
  {
    allGames {
      id
      alias
      label
      abbr
      date
      activeTicket
      ticketId {
        id
        employeeName
      }
    }
  }
`;
const MultiStepFormBase = () => {
  const [allGames, setAllGames] = useState();
  const [fetchAllGames] = useLazyQuery(ALL_GAMES, {
    fetchPolicy: 'network-only',
    onCompleted: async res => {
      const _allGames = await res?.allGames.filter(game => game.activeTicket);

      setAllGames([
        {
          alias: 'all2020',
          label: 'All 2020 Home Games',
          abbr: '',
        },
        ..._allGames,
      ]);
    },
    onError: error => {
      console.log('Error', error);
    },
  });

  const [formData, setForm] = useForm(
    process.env.NODE_ENV === 'development' ? defaultDataFilled : defaultData,
  );
  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;

  const props = {
    formData,
    setForm,
    navigation,
    step,
  };

  useEffect(() => {
    if (!allGames) fetchAllGames();
  }, [formData, allGames]);
  switch (id) {
    case 'employeeNameStep':
      return <EmployeeNameStep {...props} />;
    case 'employeeEmailStep':
      return <EmployeeEmailStep {...props} />;
    case 'gamesStep':
      return <GamesStep {...props} allGames={allGames} />;
    case 'reqTypeStep':
      return <ReqTypeStep {...props} />;
    case 'numberOfTicketsStep':
      return <NumberOfTicketsStep {...props} />;
    case 'seatingPreferenceStep':
      return <SeatingPreferenceStep {...props} />;
    case 'notesStep':
      return <NotesStep {...props} />;
    case 'reasonForRequestStep':
      return <ReasonForRequestStep {...props} />;
    case 'aprrovedStep':
      return <AprrovedStep {...props} />;
    case 'deliveryStep':
      return <DeliveryStep {...props} />;
    case 'recipientNameStep':
      return <RecipientNameStep {...props} />;
    case 'recipientEmailStep':
      return <RecipientEmailStep {...props} />;
    case 'recipientPhoneStep':
      return <RecipientPhoneStep {...props} />;
    case 'recipientCompanyStep':
      return <RecipientCompanyStep {...props} />;
    case 'reviewForm':
      return <ReviewForm {...props} allGames={allGames} />;
    case 'submissionForm':
      return <SubmissionForm {...props} />;
    default:
      return null;
  }
};

export default MultiStepFormBase;
