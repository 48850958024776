import React, { setState, useState, useEffect, useRef, createRef } from 'react';
import cx from 'classnames';

import MultiStepForm from './MultiStepForm';

import logo from '../../../images/logo.png';

import styles from '../Forms.scss';

const formEntery = 'LionsCred19';

const FormCredsBaseExt = () => {
  return (
    <div className={styles.forms}>
      <div className={styles.forms__header}>
        <div className={styles.forms__header__logo}>
          <img src={logo} alt="" />
        </div>
        <div className={styles.forms__header__content}>
          <h1>Credentials</h1>
          <h5>2020 Credential Request Form</h5>
        </div>
      </div>
      <div className={styles.forms__content}>
        <MultiStepForm />
      </div>
      <div className={styles.forms__footer} />
    </div>
  );
};

export default FormCredsBaseExt;
