export const HOME = '/';
export const ADMIN = '/admin';
export const TICKETS = '/tickets';
export const ADMIN_TICKETS = '/admin-tickets';
export const CREDENTIALS = '/credentials';
export const CREDENTIALS_EXT = '/request_cred_external';
export const ADMIN_CREDENTIALS = '/admin-credentials';
export const ADMIN_CREDENTIALS_EXT = '/admin-request_cred_external';
export const PARKING = '/parking';
export const ADMIN_PARKING = '/admin-parking';
export const ADMIN_GAMES = '/admin-games';
export const LOGIN = '/login';
