import React, { useState, useEffect, createRef, useRef } from 'react';
import cx from 'classnames';

import {
  Button,
  Input,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

import styles from '../../../Forms.scss';
import stylesForm from '../../../../../components/shared/Form/Form.scss';

const EmployeeNameStep = ({ setForm, formData, navigation, step }) => {
  const { employeeName } = formData;
  const { next, previous } = navigation;
  const [isValid, setIsValid] = useState(false);

  KeyboardNav(step, isValid, navigation);

  useEffect(() => {
    if (employeeName !== '') {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [formData, isValid]);

  return (
    <>
      <h4>
        <span>4</span> Your Name *
      </h4>
      <Input
        name="employeeName"
        placeholder="Enter your name …"
        value={employeeName}
        onChange={setForm}
      />
      <Navigation navigation={navigation} isValid={isValid} />
    </>
  );
};

export default EmployeeNameStep;
