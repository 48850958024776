import React from 'react';
import { withRouter } from 'react-router-dom';

import Header from '../../../components/Header';
import CredentialsExtPage from './CredentialsExt';

import styles from './Credentials.scss';

const HeaderWithRouter = withRouter(Header);

const AdminCredentialsExt = props => (
  <div className={styles.main}>
    <HeaderWithRouter />

    <CredentialsExtPage {...props} />
  </div>
);
export default AdminCredentialsExt;
