import React, { useState, useEffect, createRef, useRef } from 'react';
import cx from 'classnames';

import {
  Input,
  Radio,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';
import { positionList } from '../../../../../../data/positionList';

import styles from '../../../Forms.scss';
import stylesForm from '../../../../../components/shared/Form/Form.scss';

const PositionStep = ({ setForm, formData, navigation, step }) => {
  const { position } = formData;
  const { next, previous } = navigation;
  const [isValid, setIsValid] = useState(false);

  KeyboardNav(step, isValid, navigation);

  useEffect(() => {
    if (position !== '') {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [formData, isValid]);

  return (
    <>
      <h4>
        <span>3</span> Position *
      </h4>

      <div className={styles.form__multiple}>
        {positionList.map(positionListItem => {
          const { id, label } = positionListItem;

          return (
            <Radio
              id={id}
              key={id}
              label={label}
              name="position"
              value={id}
              checked={position === id}
              onChange={setForm}
              type="radio"
            />
          );
        })}
      </div>
      <Navigation navigation={navigation} isValid={isValid} />
    </>
  );
};

export default PositionStep;
