import React from 'react';
import styles from './Form.scss';

const ticketTypeFilter = [
  ['allTicketTypes', '- All Request Types -'],
  ['charityCommunityRelations', 'Charity/Community Relations'],
  ['formerEmployees', 'Former Employees'],
  ['media', 'Media'],
  ['gamedayWorkers', 'Gameday Workers'],
  ['localDignitariesVIPs', 'Local Dignitaries/VIPs'],
  ['mediaGuests', 'Media Guests'],
  ['nflEmployees', 'NFL Employees'],
  ['promotions', 'Promotions'],
  ['radioTvTrade', 'Radio/TV Trade'],
  ['corporatePartnershipObligation', 'Corporate Partnership Obligation'],
  ['corporatePartnershipTrade', 'Corporate Partnership Trade'],
  ['suiteTrade', 'Suite Trade'],
  ['stadiumLeaseRequirement', 'Stadium Lease Requirement'],
  ['ticketingProspect', 'Ticketing Prospect'],
  ['vendorContractor', 'Vendor/Contractor'],
  ['visitingTeam', 'Visiting Team'],
  ['youthGroup', 'Youth Group'],
  ['customTicketType', 'Custom Ticket Type'],
];

const parkingsTypeFilter = [
  ['allParkingsTypes', '- All Request Types -'],
  ['charityCommunityRelations', 'Charity/Community Relations'],
  ['formerEmployees', 'Former Employees'],
  ['media', 'Media'],
  ['gamedayWorkers', 'Gameday Workers'],
  ['localDignitariesVIPs', 'Local Dignitaries/VIPs'],
  ['mediaGuests', 'Media Guests'],
  ['nflEmployees', 'NFL Employees'],
  ['promotions', 'Promotions'],
  ['radioTvTrade', 'Radio/TV Trade'],
  ['corporatePartnershipObligation', 'Corporate Partnership Obligation'],
  ['corporatePartnershipTrade', 'Corporate Partnership Trade'],
  ['suiteTrade', 'Suite Trade'],
  ['stadiumLeaseRequirement', 'Stadium Lease Requirement'],
  ['ticketingProspect', 'Ticketing Prospect'],
  ['vendorContractor', 'Vendor/Contractor'],
  ['visitingTeam', 'Visiting Team'],
  ['youthGroup', 'Youth Group'],
  ['customParkingType', 'Custom Parking Type'],
];

const credTypeFilter = [
  ['allCredTypes', '- All Request Types -'],
  ['fieldPhoto', 'Field Photo'],
  ['gameServices', 'Game Services'],
  ['media', 'Media'],
  ['official', 'Official'],
  ['operations', 'Operations'],
  ['pregameSideline', 'Pregame Sideline'],
  ['tVCamera', 'TV Camera'],
  ['fieldPromotions', 'Field Promotions'],
  ['customCredType', 'Custom Credentials Type'],
];
const credExtTypeFilter = [
  ['allCredTypes', '- All Request Types -'],
  ['fieldPhoto', 'Field Photo'],
  ['media', 'Media (Press Box)'],
  ['tVCamera', 'TV Camera'],
  ['customCredType', 'Custom Credentials Type'],
];

const Dropdown = ({ label, page, ...others }) => (
  <>
    {label && <label className={styles.dropdown__input__label}>{label}</label>}
    <select {...others} className={styles.dropdown__input__select}>
      {page === 'tickets' &&
        ticketTypeFilter.map(([value, name]) => (
          <option value={value} key={name}>
            {name}
          </option>
        ))}
      {page === 'credentials' &&
        credTypeFilter.map(([value, name]) => (
          <option value={value} key={name}>
            {name}
          </option>
        ))}
      {page === 'credentialsExt' &&
        credExtTypeFilter.map(([value, name]) => (
          <option value={value} key={name}>
            {name}
          </option>
        ))}
      {page === 'parkings' &&
        parkingsTypeFilter.map(([value, name]) => (
          <option value={value} key={name}>
            {name}
          </option>
        ))}
    </select>
  </>
);

export default Dropdown;
