import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import Nav from './Nav';
import * as ROUTES from '../../constants/routes';
import * as USERS from '../../constants/users';

import { gamesList } from '../../../data/gamesList';
import { AuthContext } from '../../contexts/AuthContext';

import styles from './Header.scss';

import logo from '../../images/logo.png';

const HeaderBase = props => {
  const [open, setOpen] = useState(false);
  const openToggle = open ? styles.open : '';
  const { auth, location } = props;
  return (
    <header className={styles.header}>
      <div className={styles.header__top}>
        <div className={cx(styles.header__top__content, openToggle)}>
          <div className={styles.logo}>
            <Link to="#">
              <img src={logo} alt="logo" />
            </Link>
          </div>

          <Nav navOpen={setOpen} auth={auth} location={location} />

          <div className={styles.profile}>
            <div className={styles.profile__top}>
              {/* <Link to="">export</Link> <span> . </span> */}
              <button onClick={() => auth.logout()}>Log Out</button>
            </div>
            <div className={styles.profile__bottom}>Welcome, {auth.name}</div>
          </div>
          <label
            onClick={() => {
              open ? setOpen(false) : setOpen(true);
            }}
            className={styles['header__top__content--toggle-label']}
          >
            <span />
          </label>
        </div>
      </div>
    </header>
  );
};

export default React.forwardRef((props, ref) => (
  <AuthContext.Consumer>
    {auth => <HeaderBase {...props} auth={auth} ref={ref} />}
  </AuthContext.Consumer>
));
