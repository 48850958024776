import React, { useState, useEffect, createRef, useRef } from 'react';
import cx from 'classnames';

import {
  Input,
  Radio,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

import styles from '../../../Forms.scss';
import stylesForm from '../../../../../components/shared/Form/Form.scss';

const NumberOfParkingsStep = ({ setForm, formData, navigation, step }) => {
  const { numberOfParkings } = formData;
  const { next, previous } = navigation;
  const [isValid, setIsValid] = useState(false);
  const [numError, setNumError] = useState({ err: '' });

  const numFeild = createRef();

  function validateNum(num) {
    const validate = /^[0-9]*$/;
    return validate.test(num);
  }
  KeyboardNav(step, isValid, navigation);

  useEffect(() => {
    if (numberOfParkings !== '' && validateNum(numberOfParkings)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }

    if (!validateNum(numberOfParkings)) {
      setNumError({ err: 'Number is not Valid!' });
    } else {
      setNumError({ err: '' });
    }
  }, [formData, isValid]);

  return (
    <>
      <h4>
        <span>5</span> Number of Parking Passes *
      </h4>

      <label
        htmlFor="numberOfParkings"
        className={cx(
          stylesForm.input,
          numError.err !== '' ? stylesForm.input__email_error : '',
        )}
      >
        <input
          type="text"
          placeholder=" "
          id="numberOfParkings"
          name="numberOfParkings"
          value={numberOfParkings}
          onChange={setForm}
          ref={numFeild}
          autoComplete="off"
        />
        <span className={stylesForm.label}>Type your answer here …</span>
        <span className={stylesForm.border} />
        {numError.err !== '' && (
          <span className={stylesForm.email__error}>{numError.err}</span>
        )}
      </label>

      <Navigation navigation={navigation} isValid={isValid} />
    </>
  );
};

export default NumberOfParkingsStep;
