import React from 'react';
import { RouterContext } from '../../contexts/RouteContext';
import HeaderBase from './Header';

const Header = props => {
  const { location } = props;
  return (
    <RouterContext.Provider value={location}>
      <HeaderBase {...props} />
    </RouterContext.Provider>
  );
};

export default Header;
