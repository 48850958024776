/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { Modal } from '../../../../../components/shared';

import styles from '../../../Forms.scss';
import { credsTypeList } from '../../../../../../data/credsTypeList';

const CREATE_TICKET = gql`
  mutation CreateCred($credInput: CredInput!) {
    createCred(credInput: $credInput) {
      employeeName
    }
  }
`;

const ReviewForm = ({ formData, navigation, allGames }) => {
  const {
    employeeName,
    employeeEmail,
    games,
    reqType,
    customReqType,
    numberOfCreds,
    notes,
    reasonForRequest,
    recipientName,
    recipientEmail,
    recipientCompany,
    delivery,
    recipientPhone,
  } = formData;

  const { next, previous, go } = navigation;
  const [createCredError, setTreateCredError] = useState();
  const [createCred] = useMutation(CREATE_TICKET, {
    onCompleted: () => {
      setTreateCredError(null);
      next();
    },
    onError: error => {
      console.log(error);
      setTreateCredError('Something went wrong! Please try again later.');
    },
  });

  const [modalData, setModalData] = useState({
    openModal: false,
    text: '',
    onConfirm: null,
  });
  const [gamesArray, setGamesArray] = useState([]);
  const [gamesArrayAbbr, setGamesArrayAbbr] = useState([]);

  const submitModal = () => {
    setModalData({
      openModal: true,
      text: 'Submit Form',
      onConfirm: handleSubmit,
    });
  };

  const handleSubmit = () => {
    createCred({
      variables: {
        credInput: {
          employeeName,
          employeeEmail,
          reqType,
          customReqType,
          delivery,
          games: gamesArrayAbbr,
          notes,
          numberOfCreds,
          reasonForRequest,
          recipientEmail,
          recipientName,
          recipientPhone,
          recipientCompany,
        },
      },
    });
  };

  useEffect(() => {
    let _gamesArray = [];
    let _gamesArrayAbbr = [];
    Object.keys(games).forEach(keyName => {
      if (games.all2020) {
        _gamesArray = [];
        _gamesArrayAbbr = [];
        _gamesArray.push(allGames[0]);
        allGames.forEach(game =>
          game.alias !== 'all2020' ? _gamesArrayAbbr.push(game.abbr) : null,
        );
      } else if (keyName !== 'all2020' && games[keyName]) {
        allGames.forEach(item => {
          if (keyName === item.alias) {
            _gamesArray.push(item);
            _gamesArrayAbbr.push(item.abbr);
          }
        });
      }
    });
    setGamesArray(_gamesArray);
    setGamesArrayAbbr(_gamesArrayAbbr);
  }, [formData]);
  return (
    <>
      <div className={styles.form__review}>
        <h2>Review</h2>
        {employeeName !== '' && (
          <div className={styles.form__review__item}>
            <h4>Your Name *</h4>
            <h2>{employeeName}</h2>
            <button onClick={() => go('employeeNameStep')}>Edit</button>
          </div>
        )}
        {employeeEmail !== '' && (
          <div className={styles.form__review__item}>
            <h4>Your Email Address *</h4>
            <h2>{employeeEmail}</h2>
            <button onClick={() => go('employeeEmailStep')}>Edit</button>
          </div>
        )}
        {gamesArray !== {} && (
          <div
            className={cx(
              styles.form__review__item,
              styles.form__review__item__list,
            )}
          >
            <h4>Game(s) Requested *</h4>
            <button onClick={() => go('gamesStep')}>Edit</button>
            <div className={styles.form__review__item__list__item}>
              {gamesArray.map(item => (
                <span key={item.abbr}>{item.label}</span>
              ))}
            </div>
          </div>
        )}
        {(reqType !== '' || reqType !== 'pregameSideline') && (
          <div className={styles.form__review__item}>
            <h4>Type of Request *</h4>
            {reqType === 'customReq' ? (
              <h2>{customReqType}</h2>
            ) : (
              credsTypeList.map(req => {
                return req.id === reqType ? (
                  <h2 key={req.id}>
                    {req.label}
                    {reqType === 'pregameSideline' ? ' - ' + customReqType : ''}
                  </h2>
                ) : null;
              })
            )}
            <button onClick={() => go('credTypeStep')}>Edit</button>
          </div>
        )}
        {numberOfCreds !== '' && (
          <div className={styles.form__review__item}>
            <h4>Number of Credentials *</h4>
            <h2>{numberOfCreds}</h2>
            <button onClick={() => go('numberOfCredsStep')}>Edit</button>
          </div>
        )}

        {reasonForRequest !== '' && (
          <div className={styles.form__review__item}>
            <h4>Reason for Request *</h4>
            <h2>{reasonForRequest}</h2>
            <button onClick={() => go('reasonForRequestStep')}>Edit</button>
          </div>
        )}
        {notes !== '' && (
          <div className={styles.form__review__item}>
            <h4>Notes</h4>
            <h2>{notes}</h2>
            <button onClick={() => go('notesStep')}>Edit</button>
          </div>
        )}

        {recipientName !== '' && (
          <div className={styles.form__review__item}>
            <h4>Recipient Name(s) *</h4>
            <h2>{recipientName}</h2>
            <button onClick={() => go('recipientNameStep')}>Edit</button>
          </div>
        )}
        {recipientCompany !== '' && (
          <div className={styles.form__review__item}>
            <h4>Recipient Company</h4>
            <h2>{recipientCompany}</h2>
            <button onClick={() => go('recipientCompanyStep')}>Edit</button>
          </div>
        )}
      </div>
      <div className={styles.form__navigation}>
        <button onClick={previous}>Previous</button>
        <button onClick={() => submitModal()}>Submit</button>
      </div>
      <Modal canConfirm setModalData={setModalData} modalData={modalData}>
        {!createCredError ? modalData.text : ''}
        {createCredError || ''}
      </Modal>
    </>
  );
};

export default ReviewForm;
