/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Modal } from '../index';
import styles from './BoxRowItem.scss';
import { AuthContext } from '../../../contexts/AuthContext';
import { FetchApiContext } from '../../../contexts/FetchApiContext';

import { parkingsTypeList } from '../../../../data/parkingsTypeList';
import { parkingLotLocatoin } from '../../../../data/parkingLotLocation';
import { sendEmailHandler } from '../../../../helpers/sendEmail';

const UPDATE_PARKING = gql`
  mutation UpdateParkings($updateParkingInput: UpdateParkingInput!) {
    updateParkings(updateParkingInput: $updateParkingInput) {
      id
    }
  }
`;

function ParkingsRowItems(props) {
  const { page, item } = props;

  const auth = React.useContext(AuthContext);
  const { role: userRoles } = auth;

  const fetchApi = React.useContext(FetchApiContext);
  const itemStatus = item.status;
  const [open, setOpen] = useState(false);

  const [modalData, setModalData] = useState({
    openModal: false,
    text: '',
    onConfirm: null,
  });

  const openToggle = open ? styles.open : '';

  const [updateParking] = useMutation(UPDATE_PARKING, {
    onCompleted: () => {
      fetchApi();
    },
    onError: error => {
      console.log(error);
    },
  });

  const approveModal = () => {
    setModalData({
      openModal: true,
      text: 'Approve request',
      onConfirm: approveHandler,
      item,
    });
  };

  const approveHandler = () => {
    updateParking({
      variables: {
        updateParkingInput: {
          parkingId: item.id,
          status: 'approved',
        },
      },
    });
  };

  const denyRequestModal = () => {
    setModalData({
      openModal: true,
      text: 'Cancel request',
      note: true,
      onConfirm: denyRequestHandler,
    });
  };

  const denyRequestHandler = info => {
    updateParking({
      variables: {
        updateParkingInput: {
          parkingId: item.id,
          status: 'denied',
          note: info.noteFeild,
        },
      },
    });
  };

  const fulfillModal = () => {
    setModalData({
      openModal: true,
      text: 'Fulfill request',
      noteA: 'Account Number',
      noteB: 'Pass Number',
      noteC: 'Lot Location',
      onConfirm: fulfillHandler,
      item,
    });
  };

  const fulfillHandler = info => {
    updateParking({
      variables: {
        updateParkingInput: {
          parkingId: item.id,
          status: 'fulfilled',
          accountNum: info.noteAFeild,
          passNumber: info.noteBFeild,
          lotLocation: info.noteCFeild,
        },
      },
    });
  };

  const denyApprovedModal = () => {
    setModalData({
      openModal: true,
      text: 'Cancel approval',
      onConfirm: denyApprovedHandler,
    });
  };

  const denyApprovedHandler = () => {
    updateParking({
      variables: {
        updateParkingInput: {
          parkingId: item.id,
          status: 'pending',
        },
      },
    });
  };

  const denyFulfilledModal = () => {
    setModalData({
      openModal: true,
      text: 'Cancel fulfillment',
      onConfirm: denyFulfilledHandler,
    });
  };

  const denyFulfilledHandler = () => {
    updateParking({
      variables: {
        updateParkingInput: {
          parkingId: item.id,
          status: 'approved',
        },
      },
    });
  };

  useEffect(() => {}, [item]);

  return (
    <div
      className={cx(
        styles.box__row,
        styles[`box__row--${page}`],
        styles[`box__row--${itemStatus.toLowerCase()}`],
        openToggle,
      )}
    >
      <div
        className={cx(
          styles.box__row__header,
          styles[`box__row__header--${itemStatus.toLowerCase()}`],
          openToggle,
        )}
      >
        <a
          role="button"
          tabIndex={item.id}
          onClick={() => {
            open ? setOpen(false) : setOpen(true);
          }}
        >
          <svg
            width="15"
            height="15"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.arrow}
          >
            <path d="M8.367 11.87l6.267-6.276c.244-.231.366-.52.366-.867 0-.346-.122-.635-.366-.866l-.722-.732a1.18 1.18 0 0 0-.866-.356c-.334 0-.626.119-.877.356L7.5 7.81l-4.68-4.68a1.18 1.18 0 0 0-.866-.356c-.333 0-.625.118-.876.356l-.722.732A1.18 1.18 0 0 0 0 4.727c0 .34.119.63.356.867l6.268 6.277c.25.237.542.356.876.356.34 0 .63-.119.867-.356z" />
          </svg>
        </a>
      </div>
      <div
        className={cx(
          styles.box__row__body,
          styles[`box__row__body--${itemStatus.toLowerCase()}`],
        )}
      >
        <div className={styles.box__row__body__item}>
          <div>Employee Name</div>
          <div>{item.employeeName}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Recipient Name</div>
          <div>{item.recipientName}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Number of Parking</div>
          <div>{item.numberOfParkings}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Type of Request</div>
          {item.reqType === 'customReq' ? (
            <div>{item.customReqType}</div>
          ) : (
            parkingsTypeList.map(req => {
              return req.id === item.reqType ? (
                <div key={req.id}>{req.label}</div>
              ) : null;
            })
          )}
        </div>

        <div className={styles.box__row__body__item}>
          <div>Employee Email</div>
          <div>{item.employeeEmail}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Game(s)</div>
          <div>
            {item.games.map((game, i) => (
              <span className={styles.game__name} key={game.id}>
                {game.abbr}
                {i + 1 < item.games.length ? ', ' : ''}
              </span>
            ))}
          </div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Preferred Parking Lot Location</div>
          {parkingLotLocatoin.map(preferredParking => {
            return preferredParking.id === item.preferredParking ? (
              <div key={preferredParking.id}>{preferredParking.label}</div>
            ) : null;
          })}
        </div>
        <div className={styles.box__row__body__item}>
          <div>Recipient Company</div>
          <div>{item.recipientCompany}</div>
        </div>
        <div className={cx(styles.box__row__body__item, styles['col2--left'])}>
          <div>Notes</div>
          <div>{item.notes === '' ? ' ' : item.notes}</div>
        </div>
        <div className={cx(styles.box__row__body__item, styles['col2--right'])}>
          <div>Reason for Request</div>
          <div>{item.reasonForRequest}</div>
        </div>
        {item.accountNum && (
          <div className={styles.box__row__body__item}>
            <div>Account Number</div>
            <div>{item.accountNum}</div>
          </div>
        )}
        {item.accountNum && (
          <div className={styles.box__row__body__item}>
            <div>Account Number</div>
            <div>{item.lotLocation}</div>
          </div>
        )}
        {item.accountNum && (
          <div className={styles.box__row__body__item}>
            <div>Account Number</div>
            <div>{item.passNumber}</div>
          </div>
        )}
        {item.accountNum && <div className={styles.box__row__body__item} />}
      </div>
      <div className={cx(styles.box__row__footer)}>
        <div className={styles.box__row__footer__item}>
          <div>Date Requested</div>
          <div>{item.createdAt.substring(0, 10).replace(/-/g, ' / ')}</div>
        </div>
        <div className={styles.box__row__footer__item}>
          <div>Status</div>
          <div
            className={cx(
              styles[`box__row__footer__item--${itemStatus.toLowerCase()}`],
            )}
          >
            {item.status} (#{item.id})
          </div>
        </div>
        <div className={styles.box__row__footer__item}>
          {itemStatus === 'pending' && (
            <>
              {['1'].every(v => userRoles.includes(v)) && (
                <>
                  <button
                    onClick={() => {
                      denyRequestModal();
                    }}
                    className={cx(styles.btn, styles['btn--deny'])}
                  >
                    Deny
                  </button>
                  <button
                    className={cx(styles.btn, styles['btn--approve'])}
                    onClick={() => {
                      approveModal(item.recipientInfo);
                    }}
                  >
                    Approve
                  </button>
                </>
              )}
            </>
          )}

          {itemStatus === 'approved' && (
            <>
              {['2'].every(v => userRoles.includes(v)) && (
                <>
                  <button
                    className={cx(styles.btn, styles['btn--fulfill'])}
                    onClick={() => {
                      fulfillModal(item.recipientInfo);
                    }}
                  >
                    Fulfill
                  </button>

                  <button
                    onClick={() => {
                      denyApprovedModal();
                    }}
                    className={cx(styles.btn, styles['btn--deny'])}
                  >
                    Reset
                  </button>
                </>
              )}
            </>
          )}

          {itemStatus === 'fulfilled' && (
            <>
              {['2'].every(v => userRoles.includes(v)) && (
                <button
                  onClick={() => {
                    denyFulfilledModal();
                  }}
                  className={cx(styles.btn, styles['btn--deny'])}
                >
                  Reset
                </button>
              )}
            </>
          )}
        </div>
      </div>
      <Modal canConfirm setModalData={setModalData} modalData={modalData}>
        {modalData.text}
      </Modal>
    </div>
  );
}

export default ParkingsRowItems;
