import React, { useEffect, useState } from 'react';
import { useForm, useStep } from 'react-hooks-helper';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';

import {
  EmployeeNameStep,
  EmployeeEmailStep,
  GamesStep,
  ParkingTypeStep,
  NumberOfParkingsStep,
  ReasonForRequestStep,
  PreferredParkingStep,
  NotesStep,
  ApprovedStep,
  RecipientNameStep,
  RecipientCompanyStep,
  ReviewForm,
  SubmissionForm,
} from './Steps';

const steps = [
  { id: 'employeeNameStep' },
  { id: 'employeeEmailStep' },
  { id: 'gamesStep' },
  { id: 'parkingTypeStep' },
  { id: 'numberOfParkingsStep' },
  { id: 'reasonForRequestStep' },
  { id: 'preferredParkingStep' },
  { id: 'notesStep' },
  { id: 'approvedStep' },
  { id: 'recipientNameStep' },
  { id: 'recipientCompanyStep' },
  { id: 'ReviewForm' },
  { id: 'SubmissionForm' },
];

const defaultData = {
  employeeName: '',
  employeeEmail: '',
  games: {},
  reqType: '',
  customReqType: '',
  numberOfParkings: '',
  notes: '',
  preferredParking: '',
  reasonForRequest: '',
  approved: '',
  recipientName: '',
  recipientCompany: '',
  delivery: '',
  recipientEmail: '',
  recipientPhone: '',
};
const defaultDataFilled = {
  employeeName: 'Jane Doe',
  employeeEmail: 'jane@email.com',
  games: {
    // all2020: false,
    aug2020: true,
  },
  reqType: 'media',
  customReqType: '',
  numberOfParkings: '3',
  preferredParking: 'noPreference',
  notes: 'This is a note',
  reasonForRequest: 'This is an example reason.',
  approved: 'approved',
  recipientName: 'Jane, Brian',
  recipientCompany: 'Channel1Media',
  delivery: 'mobile',
  recipientEmail: 'brian@email.com',
  recipientPhone: '4153334444',
};
const ALL_GAMES = gql`
  {
    allGames {
      id
      alias
      label
      abbr
      date
      activeParking
    }
  }
`;
const MultiStepFormBase = () => {
  const [allGames, setAllGames] = useState();
  const [fetchAllGames] = useLazyQuery(ALL_GAMES, {
    fetchPolicy: 'network-only',
    onCompleted: async res => {
      const _allGames = await res?.allGames.filter(game => game.activeParking);

      setAllGames([
        {
          alias: 'all2020',
          label: 'All 2020 Home Games',
          abbr: '',
        },
        ..._allGames,
      ]);
    },
    onError: error => {
      console.log('Error', error);
    },
  });
  const [formData, setForm] = useForm(
    process.env.NODE_ENV === 'development' ? defaultDataFilled : defaultData,
  );
  const { step, navigation } = useStep({ initialStep: 0, steps });

  const { id } = step;

  const props = { formData, setForm, navigation, step };
  useEffect(() => {
    if (!allGames) fetchAllGames();
  }, [formData, allGames]);
  switch (id) {
    case 'employeeNameStep':
      return <EmployeeNameStep {...props} />;
    case 'employeeEmailStep':
      return <EmployeeEmailStep {...props} />;
    case 'gamesStep':
      return <GamesStep {...props} allGames={allGames} />;
    case 'parkingTypeStep':
      return <ParkingTypeStep {...props} />;
    case 'numberOfParkingsStep':
      return <NumberOfParkingsStep {...props} />;
    case 'reasonForRequestStep':
      return <ReasonForRequestStep {...props} />;
    case 'preferredParkingStep':
      return <PreferredParkingStep {...props} />;
    case 'notesStep':
      return <NotesStep {...props} />;
    case 'approvedStep':
      return <ApprovedStep {...props} />;
    case 'recipientNameStep':
      return <RecipientNameStep {...props} />;
    case 'recipientCompanyStep':
      return <RecipientCompanyStep {...props} />;
    case 'ReviewForm':
      return <ReviewForm {...props} allGames={allGames} />;
    case 'SubmissionForm':
      return <SubmissionForm {...props} />;
    default:
      return null;
  }
};

export default MultiStepFormBase;
