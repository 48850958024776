/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Modal } from '../index';
import styles from './BoxRowItem.scss';
import { AuthContext } from '../../../contexts/AuthContext';
import { FetchApiContext } from '../../../contexts/FetchApiContext';
import { credsTypeListExt } from '../../../../data/credsTypeListExt';
import { credsTypeList } from '../../../../data/credsTypeList';
import { jobFunctionList } from '../../../../data/jobFunction';

const UPDATE_CRED = gql`
  mutation UpdateCreds($updateCredInput: UpdateCredInput!) {
    updateCreds(updateCredInput: $updateCredInput) {
      id
    }
  }
`;

function CredentialsRowItems(props) {
  const { page, item } = props;
  const auth = React.useContext(AuthContext);
  const fetchApi = React.useContext(FetchApiContext);
  const { role: userRoles } = auth;
  const [loading, setLoading] = useState(false);
  const [isExternal, setIsExternal] = useState(false);
  const itemStatus = item.status;
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({
    openModal: false,
    text: '',
    onConfirm: null,
  });

  const openToggle = open ? styles.open : '';

  const [updateCred] = useMutation(UPDATE_CRED, {
    onCompleted: () => {
      fetchApi();
    },
    onError: error => {
      console.log(error);
    },
  });

  const approveModal = () => {
    setModalData({
      openModal: true,
      text: 'Approve request',
      onConfirm: approveHandler,
    });
  };

  const approveHandler = () => {
    updateCred({
      variables: {
        updateCredInput: {
          credId: item.id,
          status: 'approved',
        },
      },
    });
  };

  const denyRequestModal = () => {
    setModalData({
      openModal: true,
      text: 'Cancel request',
      note: true,
      onConfirm: denyRequestHandler,
    });
  };

  const denyRequestHandler = info => {
    updateCred({
      variables: {
        updateCredInput: {
          credId: item.id,
          status: 'denied',
          note: info.noteFeild,
        },
      },
    });
  };

  const fulfillModal = () => {
    setModalData({
      openModal: true,
      text: 'Fulfill request',
      noteA: 'Account Number',
      noteB:
        item.reqType === 'pregameSideline'
          ? 'Zone for Pregame Sideline Passes'
          : false,
      onConfirm: fulfillHandler,
      item,
    });
  };

  const fulfillHandler = info => {
   
    updateCred({
      variables: {
        updateCredInput: {
          credId: item.id,
          status: 'fulfilled',
          accountNum: info.noteAFeild,
          sidelineZone:
            item.reqType === 'pregameSideline' ? info.noteBFeild : null,
        },
      },
    });
  };

  const denyApprovedModal = () => {
    setModalData({
      openModal: true,
      text: 'Cancel approval',
      onConfirm: denyApprovedHandler,
    });
  };

  const denyApprovedHandler = () => {
    updateCred({
      variables: {
        updateCredInput: {
          credId: item.id,
          status: 'pending',
        },
      },
    });
  };

  const denyFulfilledModal = () => {
    setModalData({
      openModal: true,
      text: 'Cancel fulfillment',
      onConfirm: denyFulfilledHandler,
    });
  };

  const denyFulfilledHandler = () => {
    updateCred({
      variables: {
        updateCredInput: {
          credId: item.id,
          status: 'approved',
        },
      },
    });
  };

  useEffect(() => {
    item.credType === 'external' ? setIsExternal(true) : setIsExternal(false);
  }, [item]);

  return (
    <div
      className={cx(
        styles.box__row,
        styles[`box__row--${page}`],
        isExternal ? styles['box__row--external'] : '',
        styles[`box__row--${itemStatus.toLowerCase()}`],
        openToggle,
      )}
    >
      <div
        className={cx(
          styles.box__row__header,
          styles[`box__row__header--${itemStatus.toLowerCase()}`],
          openToggle,
        )}
      >
        <a
          role="button"
          tabIndex={item.id}
          onClick={() => {
            open ? setOpen(false) : setOpen(true);
          }}
        >
          <svg
            width="15"
            height="15"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.arrow}
          >
            <path d="M8.367 11.87l6.267-6.276c.244-.231.366-.52.366-.867 0-.346-.122-.635-.366-.866l-.722-.732a1.18 1.18 0 0 0-.866-.356c-.334 0-.626.119-.877.356L7.5 7.81l-4.68-4.68a1.18 1.18 0 0 0-.866-.356c-.333 0-.625.118-.876.356l-.722.732A1.18 1.18 0 0 0 0 4.727c0 .34.119.63.356.867l6.268 6.277c.25.237.542.356.876.356.34 0 .63-.119.867-.356z" />
          </svg>
        </a>
        {isExternal ? <div className={styles.ext__badge}>Ext</div> : null}
      </div>

      <div
        className={cx(
          styles.box__row__body,
          styles[`box__row__body--${itemStatus.toLowerCase()}`],
        )}
      >
        <div className={styles.box__row__body__item}>
          <div>{isExternal ? 'Name' : 'Employee Name'}</div>
          <div>{item.employeeName}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Recipient Name</div>
          <div>{item.recipientName}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>{isExternal ? 'Email' : 'Employee Email'}</div>
          <div>{item.employeeEmail}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Type of Request</div>
          {isExternal ? (
            <>
              {item.reqTypeExt === 'customReq' ? (
                <div>{item.customReqType}</div>
              ) : (
                credsTypeListExt.map(req => {
                  return req.id === item.reqTypeExt ? (
                    <div key={req.id}>{req.label}</div>
                  ) : null;
                })
              )}
            </>
          ) : (
            <>
              {item.reqType === 'customReq' ? (
                <div>{item.customReqType}</div>
              ) : (
                credsTypeList.map(req => {
                  return req.id === item.reqType ? (
                    <div key={req.id}>
                      {req.label}
                      {item.reqType === 'pregameSideline'
                        ? ' - ' + item.customReqType
                        : ''}
                    </div>
                  ) : null;
                })
              )}
            </>
          )}
        </div>
        <div className={styles.box__row__body__item}>
          <div>Game(s)</div>
          <div>
            {item.games.map((game, i) => (
              <span className={styles.game__name} key={game.id}>
                {game.abbr}
                {i + 1 < item.games.length ? ', ' : ''}
              </span>
            ))}
          </div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Recipient Company</div>
          <div>{item.recipientCompany}</div>
        </div>

        {!isExternal ? (
          <>
            <div className={styles.box__row__body__item}>
              <div>Number of Credentials</div>
              <div>{item.numberOfCreds}</div>
            </div>
            <div className={styles.box__row__body__item} />
            <div
              className={cx(styles.box__row__body__item, styles['col2--left'])}
            >
              <div>Notes</div>
              <div>{item.notes === '' ? ' ' : item.notes}</div>
            </div>
            <div
              className={cx(styles.box__row__body__item, styles['col2--right'])}
            >
              <div>Reason for Request</div>
              <div>{item.reasonForRequest}</div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.box__row__body__item}>
              <div>Phone</div>
              <div>{item.employeePhone}</div>
            </div>
            <div className={styles.box__row__body__item}>
              <div>Recipient Email</div>
              <div>{item.recipientEmail}</div>
            </div>
            <div
              className={cx(styles.box__row__body__item, styles['col2--left'])}
            >
              <div>Notes</div>
              <div>{item.notes === '' ? ' ' : item.notes}</div>
            </div>
            <div
              className={cx(styles.box__row__body__item, styles['col2--right'])}
            >
              <div>Job Function</div>
              {jobFunctionList.map(note => {
                return note.id === item.jobFunction ? (
                  <div key={note.id}>{note.label}</div>
                ) : null;
              })}
            </div>
            <div className={styles.box__row__body__item}>
              <div>Recipient Phone</div>
              <div>{item.recipientPhone}</div>
            </div>
            <div className={styles.box__row__body__item}>
              <div>Parking</div>
              <div>{item.parking}</div>
            </div>
            <div className={styles.box__row__body__item}>
              <div>Position</div>
              <div>{item.position}</div>
            </div>
            <div className={styles.box__row__body__item}>
              <div>Sat Truck</div>
              <div>{item.satTruck === 'yesTruck' ? 'yes' : 'No'}</div>
            </div>
            <div className={styles.box__row__body__item}>
              <div>Address</div>
              <div> {item.address}</div>
            </div>
            <div className={styles.box__row__body__item}>
              <div>City</div>
              <div>{item.city}</div>
            </div>
            <div className={styles.box__row__body__item}>
              <div>State</div>
              <div>{item.state}</div>
            </div>
            <div className={styles.box__row__body__item}>
              <div>Zip Code</div>
              <div>{item.zipCode}</div>
            </div>
          </>
        )}
      </div>

      <div
        className={cx(
          styles.box__row__footer,
          item.status === 'fulfilled' ? styles.box__row__footer__col4 : '',
          item.status === 'fulfilled' && item.reqType === 'pregameSideline'
            ? styles.box__row__footer__col5
            : '',
        )}
      >
        <div className={styles.box__row__footer__item}>
          <div>Date Requested</div>
          <div>{item.createdAt.substring(0, 10).replace(/-/g, ' / ')}</div>
        </div>
        {item.accountNum && (
          <div className={styles.box__row__footer__item}>
            <div>Account Number</div>
            <div>{item.accountNum}</div>
          </div>
        )}
        {item.reqType &&
          item.status === 'fulfilled' &&
          item.reqType === 'pregameSideline' && (
            <div className={styles.box__row__footer__item}>
              <div>Sideline Zone</div>
              <div>{item.sidelineZone}</div>
            </div>
          )}
        <div className={styles.box__row__footer__item}>
          <div>Status</div>
          <div
            className={cx(
              styles[`box__row__footer__item--${itemStatus.toLowerCase()}`],
            )}
          >
            {item.status} (#{item.id})
          </div>
        </div>
        <div className={styles.box__row__footer__item}>
          {itemStatus === 'pending' && (
            <>
              {['1'].every(v => userRoles.includes(v)) && (
                <>
                  <button
                    onClick={() => {
                      denyRequestModal();
                    }}
                    className={cx(styles.btn, styles['btn--deny'])}
                  >
                    Deny
                  </button>
                  <button
                    className={cx(styles.btn, styles['btn--approve'])}
                    onClick={() => {
                      approveModal(item.recipientInfo);
                    }}
                  >
                    Approve
                  </button>
                </>
              )}
            </>
          )}

          {itemStatus === 'approved' && (
            <>
              {['2'].every(v => userRoles.includes(v)) && (
                <>
                  <button
                    className={cx(styles.btn, styles['btn--fulfill'])}
                    onClick={() => {
                      fulfillModal(item.recipientInfo);
                    }}
                  >
                    Fulfill
                  </button>

                  <button
                    onClick={() => {
                      denyApprovedModal();
                    }}
                    className={cx(styles.btn, styles['btn--deny'])}
                  >
                    Reset
                  </button>
                </>
              )}
            </>
          )}

          {itemStatus === 'fulfilled' && (
            <>
              {['2'].every(v => userRoles.includes(v)) && (
                <button
                  onClick={() => {
                    denyFulfilledModal();
                  }}
                  className={cx(styles.btn, styles['btn--deny'])}
                >
                  Reset
                </button>
              )}
            </>
          )}
        </div>
      </div>
      <Modal canConfirm setModalData={setModalData} modalData={modalData}>
        {modalData.text}
      </Modal>
    </div>
  );
}

export default CredentialsRowItems;
