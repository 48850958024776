import React, { useState, useEffect, createRef, useRef } from 'react';
import cx from 'classnames';

import { Navigation, KeyboardNav } from '../../../../../components/shared';

import styles from '../../../Forms.scss';
import stylesForm from '../../../../../components/shared/Form/Form.scss';

const NumberOfTicketsStep = ({ setForm, formData, navigation, step }) => {
  const { numberOfTickets } = formData;
  const [isValid, setIsValid] = useState(false);
  const [numError, setNumError] = useState({ err: '' });

  const numFeild = createRef();

  function validateNum(num) {
    const validate = /^[0-9]*$/;
    return validate.test(num);
  }
  KeyboardNav(step, isValid, navigation);

  useEffect(() => {
    if (numberOfTickets !== '' && validateNum(numberOfTickets)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }

    if (!validateNum(numberOfTickets)) {
      setNumError({ err: 'Number is not Valid!' });
    } else {
      setNumError({ err: '' });
    }
  }, [formData, isValid]);

  return (
    <>
      <h4>
        <span>5</span> Number of Tickets *
      </h4>

      <label
        htmlFor="numberOfTickets"
        className={cx(
          stylesForm.input,
          numError.err !== '' ? stylesForm.input__email_error : '',
        )}
      >
        <input
          type="text"
          placeholder=" "
          id="numberOfTickets"
          name="numberOfTickets"
          value={numberOfTickets}
          onChange={setForm}
          ref={numFeild}
          autoComplete="off"
        />
        <span className={stylesForm.label}>Type your answer here …</span>
        <span className={stylesForm.border} />
        {numError.err !== '' && (
          <span className={stylesForm.email__error}>{numError.err}</span>
        )}
      </label>

      <Navigation navigation={navigation} isValid={isValid} />
    </>
  );
};

export default NumberOfTicketsStep;
