import React, { useState, useEffect } from 'react';

import {
  Input,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

const RecipientEmailStep = ({ setForm, formData, navigation, step }) => {
  const { recipientEmail } = formData;
  const [isValid, setIsValid] = useState(false);

  KeyboardNav(step, isValid, navigation);

  useEffect(() => {
    if (recipientEmail !== '') {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [formData, isValid]);

  return (
    <>
      <h4>
        <span>13</span> Recipient Email Address *
      </h4>
      <Input
        name="recipientEmail"
        placeholder="Type your answer here …"
        type="text"
        value={recipientEmail}
        onChange={setForm}
      />

      <Navigation navigation={navigation} isValid={isValid} />
    </>
  );
};

export default RecipientEmailStep;
