import React, { useEffect, useState, createRef } from 'react';
import cx from 'classnames';

import {
  Input,
  Radio,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';
import { parkingsTypeList } from '../../../../../../data/parkingsTypeList';
import styles from '../../../Forms.scss';
import stylesForm from '../../../../../components/shared/Form/Form.scss';

const ParkingTypeStep = ({ setForm, formData, navigation, step }) => {
  const { reqType, customReqType } = formData;
  const { next, previous } = navigation;

  const hiddencustomReq = createRef();
  const custominput = createRef();
  KeyboardNav(step, reqType !== '', navigation);

  function hiddenRadioHandler() {
    hiddencustomReq.current ? hiddencustomReq.current.click() : null;
  }

  useEffect(() => {
    custominput.current.addEventListener('focus', hiddenRadioHandler);
    return () => {
      custominput.current
        ? custominput.current.removeEventListener('focus', hiddenRadioHandler)
        : null;
    };
  }, [formData]);

  return (
    <div>
      <h4>
        <span>4</span> Type of Request *
      </h4>

      <div className={styles.form__multiple}>
        {parkingsTypeList.map(typeItem => {
          const { id, label } = typeItem;

          return (
            <Radio
              id={id}
              key={id}
              label={label}
              name="reqType"
              value={id}
              checked={reqType === id}
              onChange={setForm}
              type="radio"
            />
          );
        })}
        <input
          id="hiddencustomReq"
          key="hiddencustomReq"
          label="hiddencustomReq"
          name="reqType"
          className={stylesForm.radio__input}
          value="customReq"
          onChange={setForm}
          type="radio"
          ref={hiddencustomReq}
        />

        <label
          htmlFor="customReqType"
          className={cx(stylesForm.input, stylesForm.input__select_item)}
        >
          <input
            type="text"
            placeholder=" "
            id="customReqType"
            name="customReqType"
            onChange={setForm}
            value={customReqType}
            ref={custominput}
          />
          <span className={stylesForm.label}>
            Type here for other type of request ...
          </span>
        </label>
      </div>
      <Navigation navigation={navigation} isValid={reqType !== ''} />
    </div>
  );
};

export default ParkingTypeStep;
