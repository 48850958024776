import React from 'react';
import cx from 'classnames';

import styles from './Form.scss';

const Input = ({
  placeholder,
  name,
  selectitem,
  children,
  labelStyle,
  type = 'text',
  ...otherProps
}) => {
  return (
    <>
      {type === 'text' || type === 'date' ? (
        <>
          <label htmlFor={name} className={styles.input}>
            <input
              type={type}
              id={name}
              name={name}
              placeholder=" "
              {...otherProps}
              autoComplete="off"
            />
            <span className={cx(styles.label, labelStyle)}>{placeholder}</span>
            <span className={styles.border} />
          </label>
        </>
      ) : (
        <>
          <label
            htmlFor={name}
            className={cx(styles.input, styles.input__textarea)}
          >
            <textarea
              type={type}
              id={name}
              name={name}
              placeholder=" "
              {...otherProps}
              autoComplete="off"
            />
            <span className={styles.label}>{placeholder}</span>
            <span className={styles.border} />
          </label>
        </>
      )}
    </>
  );
};

export default Input;
