import React, { useState, useEffect, createRef, useRef } from 'react';
import cx from 'classnames';

import {
  Input,
  Radio,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

import styles from '../../../Forms.scss';
import stylesForm from '../../../../../components/shared/Form/Form.scss';
import CredentialsPage from '../../../../Admin/Credentials/Credentials';

const NumberOfCredsStep = ({ setForm, formData, navigation, step }) => {
  const { reqType, numberOfCreds } = formData;
  const { next, previous, go } = navigation;
  const [isValid, setIsValid] = useState(false);
  const [numError, setNumError] = useState({ err: '' });

  const numFeild = createRef();

  function validateNum(num) {
    const validate = /^[0-9]*$/;
    return validate.test(num);
  }
  KeyboardNav(step, isValid, navigation);

  useEffect(() => {
    if (numberOfCreds !== '' && validateNum(numberOfCreds)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }

    if (!validateNum(numberOfCreds)) {
      setNumError({ err: 'Number is not Valid!' });
    } else {
      setNumError({ err: '' });
    }
  }, [formData, isValid]);

  return (
    <>
      <h4>
        <span>5</span> Number of Credentials *
      </h4>

      <label
        htmlFor="numberOfCreds"
        className={cx(
          stylesForm.input,
          numError.err !== '' ? stylesForm.input__email_error : '',
        )}
      >
        <input
          type="text"
          placeholder=" "
          id="numberOfCreds"
          name="numberOfCreds"
          value={numberOfCreds}
          onChange={setForm}
          ref={numFeild}
          autoComplete="off"
        />
        <span className={stylesForm.label}>Type your answer here …</span>
        <span className={stylesForm.border} />
        {numError.err !== '' && (
          <span className={stylesForm.email__error}>{numError.err}</span>
        )}
      </label>

      {/* <Navigation navigation={navigation} isValid={isValid} /> */}
      <div className={styles.form__navigation}>
        <button
          onClick={
            reqType === 'pregameSideline' ? previous : () => go('credTypeStep')
          }
        >
          Previous
        </button>
        <button onClick={next} disabled={!isValid}>
          Next
        </button>
        {!isValid ? (
          <span className={styles.required_feild}>
            Fill all the requiered feilds
          </span>
        ) : (
          <span className={styles.required_feild}>
            Press <span>Enter</span>
          </span>
        )}
      </div>
    </>
  );
};

export default NumberOfCredsStep;
