import React, { useState, useEffect } from 'react';

import {
  Radio,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

import styles from '../../../Forms.scss';

const seatingPreferenceItems = [
  { id: 'mobile', label: 'Mobile Tickets to recipient' },
  {
    id: 'hardstock',
    label: 'Hard Stock Tickets to you/requestor',
  },
];

const DeliveryStep = ({ setForm, formData, navigation, step }) => {
  const { delivery } = formData;
  const { next, previous } = navigation;
  const [isValid, setIsValid] = useState(false);

  KeyboardNav(step, isValid, navigation);

  useEffect(() => {
    if (delivery !== '') {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [formData, isValid]);

  return (
    <>
      <h4>
        <span>12</span> How will these tickets be delivered? *
      </h4>
      <h5>
        (Mobile tickets are MANDATORY except for approved business exceptions.
        If hard stock is selected, business case exception must be supplied
        within 7) Notes section. You can click ‘Previous’ to navigate back.)
      </h5>
      <div className={styles.form__multiple__col1}>
        {seatingPreferenceItems.map(prefItem => {
          const { id, label } = prefItem;

          return (
            <Radio
              id={id}
              key={id}
              label={label}
              name="delivery"
              value={id}
              checked={delivery === id}
              onChange={setForm}
              type="radio"
            />
          );
        })}
      </div>
      <Navigation navigation={navigation} isValid={isValid} />
    </>
  );
};

export default DeliveryStep;
