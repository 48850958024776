import React, { useState, useEffect } from 'react';

import {
  Input,
  Radio,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

import styles from '../../../Forms.scss';
import stylesForm from '../../../../../components/shared/Form/Form.scss';

const RecipientCompanyStep = ({ setForm, formData, navigation, step }) => {
  const { recipientCompany } = formData;
  const { next, previous } = navigation;
  const [isValid, setIsValid] = useState(true);

  KeyboardNav(step, isValid, navigation);

  return (
    <>
      <h4>
        <span>9</span> Recipient Company (if applicable)
      </h4>
      <Input
        name="recipientCompany"
        placeholder="Type your answer here …"
        value={recipientCompany}
        onChange={setForm}
      />
      <Navigation navigation={navigation} isValid={isValid} />
    </>
  );
};

export default RecipientCompanyStep;
