export const positionList = [
  {
    id: 'sportsEditor',
    label: 'Sports Editor',
  },
  {
    id: 'sportsDirector',
    label: 'Sports Director',
  },
  {
    id: 'newsEditor',
    label: 'News Editor',
  },
  {
    id: 'photoEditor',
    label: 'Photo Editor',
  },
  {
    id: 'other',
    label: 'Other – Please specify in Special Request/Comments section.',
  },
];
