import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import cx from 'classnames';
import Loader from 'react-loader-spinner';
import { AuthContext } from '../../../contexts/AuthContext';
import { FetchApiContext } from '../../../contexts/FetchApiContext';
import { pageContext } from '../../../contexts/PageContext';
import * as ROUTES from '../../../constants/routes';
import FilterNav from '../../../components/FilterNav/FilterNav';
import { exportCsv } from '../../../../helpers/exportCsv';
import { BoxRow, BoxContainer } from '../../../components/shared';
import Dropdown from '../../../components/shared/Form/Dropdown';

import styles from './Credentials.scss';

const ALL_CREDS = gql`
  query AllCreds {
    allCreds(limit: 0) {
      id
      employeeName
      employeePhone
      employeeEmail
      games {
        id
        abbr
      }
      reqType
      customReqType
      numberOfCreds
      notes
      reasonForRequest
      delivery
      recipientName
      recipientEmail
      recipientPhone
      recipientCompany
      status
      createdAt
      accountNum
      sidelineZone
      position
      address
      city
      state
      zipCode
      jobFunction
      parking
      satTruck
      credType
      reqTypeExt
    }
  }
`;

const CredentialsPage = props => {
  const { history } = props;
  const auth = React.useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [exportUrl, setExportUrl] = useState();
  const [exportPendingUrl, setExportPendingUrl] = useState();
  const [exportApprovedUrl, setExportApprovedUrl] = useState();
  const [exportFulfilledUrl, setExportFulfilledUrl] = useState();
  const [credsList, setCredsList] = useState();
  const [credsStatus, setCredsStatus] = useState();
  const [fetchApi, setFetchApi] = useState(true);
  const [filterStatus, setFilterStatus] = useState({
    credType: 'allCredTypes',
    gameDate: '',
  });

  const [allCredsFetch] = useLazyQuery(ALL_CREDS, {
    fetchPolicy: 'network-only',
    onCompleted: res => {
      createStatusArrays(res.allCreds);
      setLoading(false);
    },
    onError: err => {
      console.log(err);
      setLoading(false);
    },
  });
  const [loadMore, setLoadMore] = useState({
    pending: 5,
    approved: 5,
    fulfilled: 5,
  });

  const createStatusArrays = async creds => {
    setCredsList(creds);

    const pendingItems = [];
    const approvedItems = [];
    const fulfilledItems = [];
    let filteredCreds = [];

    switch (filterStatus.credType) {
      case 'allCredTypes':
        filteredCreds = await creds;
        break;
      case 'fieldContestant':
        filteredCreds = await credsList.filter(item =>
          item.reqType.includes('fieldContestant'),
        );
        break;
      case 'fieldPhoto':
        filteredCreds = await credsList.filter(item =>
          item.reqType.includes('fieldPhoto'),
        );
        break;
      case 'media':
        filteredCreds = await credsList.filter(item =>
          item.reqType.includes('media'),
        );
        break;
      case 'official':
        filteredCreds = await credsList.filter(item =>
          item.reqType.includes('official'),
        );
        break;
      case 'operations':
        filteredCreds = await credsList.filter(item =>
          item.reqType.includes('operations'),
        );
        break;
      case 'pregameSideline':
        filteredCreds = await credsList.filter(item =>
          item.reqType.includes('pregameSideline'),
        );
        break;
      case 'tVCamera':
        filteredCreds = await credsList.filter(item =>
          item.reqType.includes('tVCamera'),
        );
        break;
      case 'fieldPromotions':
        filteredCreds = await credsList.filter(item =>
          item.reqType.includes('fieldPromotions'),
        );
        break;
      case 'customCredType':
        filteredCreds = await credsList.filter(
          item => item.customReqType !== '',
        );
        break;
      default:
        console.log('No creds!');
    }

    switch (filterStatus.gameDate) {
      case '':
        filteredCreds = await filteredCreds;
        break;

      case 'NYJ':
        filteredCreds = filteredCreds.filter(item =>
          item.games.some(e => e.abbr === 'NYJ'),
        );
        break;

      case 'BUF':
        filteredCreds = filteredCreds.filter(item =>
          item.games.some(e => e.abbr === 'BUF'),
        );
        break;

      case 'CHI':
        filteredCreds = filteredCreds.filter(item =>
          item.games.some(e => e.abbr === 'CHI'),
        );
        break;

      case 'NO':
        filteredCreds = filteredCreds.filter(item =>
          item.games.some(e => e.abbr === 'NO'),
        );
        break;

      case 'IND':
        filteredCreds = filteredCreds.filter(item =>
          item.games.some(e => e.abbr === 'IND'),
        );
        break;

      case 'WSH':
        filteredCreds = filteredCreds.filter(item =>
          item.games.some(e => e.abbr === 'WSH'),
        );
        break;

      case 'HOU':
        filteredCreds = filteredCreds.filter(item =>
          item.games.some(e => e.abbr === 'HOU'),
        );
        break;

      case 'GB':
        filteredCreds = filteredCreds.filter(item =>
          item.games.some(e => e.abbr === 'GB'),
        );
        break;

      case 'TB':
        filteredCreds = filteredCreds.filter(item =>
          item.games.some(e => e.abbr === 'TB'),
        );
        break;

      case 'MIN':
        filteredCreds = filteredCreds.filter(item =>
          item.games.some(e => e.abbr === 'MIN'),
        );
        break;

      default:
        console.log('No creds!');
    }
    exportCsv('creds', filteredCreds).then(res => setExportUrl(res));
    exportCsv('creds', filteredCreds, 'pending').then(res =>
      setExportPendingUrl(res),
    );
    exportCsv('creds', filteredCreds, 'approved').then(res =>
      setExportApprovedUrl(res),
    );
    exportCsv('creds', filteredCreds, 'fulfilled').then(res =>
      setExportFulfilledUrl(res),
    );
    await filteredCreds.map(item => {
      const { status } = item;
      switch (status) {
        case 'pending':
          pendingItems.push(item);
          break;
        case 'approved':
          approvedItems.push(item);
          break;
        case 'fulfilled':
          fulfilledItems.push(item);
          break;
        default:
          console.log('No status found!');
      }
      return false;
    });

    setCredsStatus({
      pending: pendingItems,
      approved: approvedItems,
      fulfilled: fulfilledItems,
    });
  };
  const filterArray = value => {
    setFilterStatus({
      ...filterStatus,
      credType: value,
    });
    fetchApiHandler();
  };

  const fetchApiHandler = () => {
    setFetchApi(true);
    setLoading(true);
    allCredsFetch();
  };

  useEffect(() => {
    if (!auth.token) {
      const jwtAuth = JSON.parse(localStorage.getItem('jwtAuth'));
      !jwtAuth ? history.push(ROUTES.LOGIN) : null;
    }
    if (auth.token && fetchApi) {
      setLoading(true);
      allCredsFetch();
    }
  }, [auth, credsList, filterStatus]);
  return (
    <>
      <FilterNav
        setFilterStatus={setFilterStatus}
        filterStatus={filterStatus}
        fetchApiHandler={fetchApiHandler}
      />
      <div className={styles.body}>
        <div className={styles.filter}>
          <div className={styles.filter__type}>
            <div className={styles.filter__type__item}>
              <Dropdown
                onChange={item => filterArray(item.target.value)}
                page="credentials"
              />
            </div>
          </div>
          <button
            className={styles.btn__filter}
            onClick={() => fetchApiHandler()}
          >
            Refresh
          </button>
          <a
            href={exportUrl}
            download={`credentials-${new Date().getFullYear()}-${new Date().getMonth() +
              1}-${new Date().getDate()}-${new Date().getHours()}_${new Date().getMinutes()}.csv`}
          >
            <button className={styles.btn__filter}>Export</button>{' '}
          </a>
        </div>
        <pageContext.Provider value="credentials">
          {loading ? (
            <div className={styles.loader}>
              <Loader type="Grid" color="#1192e2" height={40} width={40} />
            </div>
          ) : (
            <FetchApiContext.Provider value={fetchApiHandler}>
              <BoxContainer title="Pending" exportUrl={exportPendingUrl}>
                {credsStatus &&
                  credsStatus.pending.map((item, i) => {
                    return i < loadMore.pending ? (
                      <BoxRow item={item} key={item.id} />
                    ) : null;
                  })}
                {credsStatus && loadMore.pending < credsStatus.pending.length && (
                  <button
                    onClick={() =>
                      setLoadMore({
                        ...loadMore,
                        pending: loadMore.pending + 5,
                      })
                    }
                    className={cx(
                      styles.load_more,
                      styles['load_more--pending'],
                    )}
                  >
                    Load More +
                  </button>
                )}
                {credsStatus && credsStatus.pending.length <= 0 && (
                  <p className={styles.no_item}>No Request</p>
                )}
              </BoxContainer>
              <BoxContainer title="Approved" exportUrl={exportApprovedUrl}>
                {credsStatus &&
                  credsStatus.approved.map((item, i) => {
                    return i < loadMore.approved ? (
                      <BoxRow item={item} key={item.id} />
                    ) : null;
                  })}
                {credsStatus &&
                  loadMore.approved < credsStatus.approved.length && (
                    <button
                      onClick={() =>
                        setLoadMore({
                          ...loadMore,
                          approved: loadMore.approved + 5,
                        })
                      }
                      className={cx(
                        styles.load_more,
                        styles['load_more--pending'],
                      )}
                    >
                      Load More +
                    </button>
                  )}
                {credsStatus && credsStatus.approved.length <= 0 && (
                  <p className={styles.no_item}>No Request</p>
                )}
              </BoxContainer>
              <BoxContainer title="Fulfilled" exportUrl={exportFulfilledUrl}>
                {credsStatus &&
                  credsStatus.fulfilled.map((item, i) => {
                    return i < loadMore.fulfilled ? (
                      <BoxRow item={item} key={item.id} />
                    ) : null;
                  })}
                {credsStatus &&
                  loadMore.fulfilled < credsStatus.fulfilled.length && (
                    <button
                      onClick={() =>
                        setLoadMore({
                          ...loadMore,
                          fulfilled: loadMore.fulfilled + 5,
                        })
                      }
                      className={cx(
                        styles.load_more,
                        styles['load_more--pending'],
                      )}
                    >
                      Load More +
                    </button>
                  )}
                {credsStatus && credsStatus.fulfilled.length <= 0 && (
                  <p className={styles.no_item}>No Request</p>
                )}
              </BoxContainer>
            </FetchApiContext.Provider>
          )}
        </pageContext.Provider>
      </div>
    </>
  );
};

export default CredentialsPage;
