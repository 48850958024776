import React, { useState, useEffect, createRef, useRef } from 'react';
import cx from 'classnames';

import {
  Button,
  Input,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

import styles from '../../../Forms.scss';
import stylesForm from '../../../../../components/shared/Form/Form.scss';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function validatePin(pin) {
  const formEntery = 'LionsCred20';
  return pin === formEntery;
}

const PincodeStep = ({ setForm, formData, navigation, step }) => {
  const { pincode } = formData;
  const { next, previous } = navigation;
  const [isValid, setIsValid] = useState(false);
  const [pinError, setPinError] = useState({ err: '' });

  const pinFeild = createRef();
  const prevPin = usePrevious(pincode);
  KeyboardNav(step, isValid, navigation);

  function pinOnFocus() {
    if (pincode !== '' && !validatePin(pincode)) {
      setPinError({ err: 'Pin is not Valid!' });
    } else {
      setPinError({ err: '' });
    }
  }

  useEffect(() => {
    if (pincode !== '' && validatePin(pincode)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }

    if (prevPin && prevPin.length > pincode.length && !validatePin(pincode)) {
      setPinError({ err: 'Pin is not Valid!' });
    } else if (validatePin(pincode)) {
      setPinError({ err: '' });
    }

    pinFeild.current.addEventListener('focusout', pinOnFocus);

    return () => {
      pinFeild.current
        ? pinFeild.current.removeEventListener('focusout', pinOnFocus)
        : null;
    };
  }, [formData, isValid]);

  return (
    <>
      <h4>
        <span>1</span> Please enter the pin code to continue *
      </h4>
      <label
        htmlFor="pincode"
        className={cx(
          stylesForm.input,
          pinError.err !== '' ? stylesForm.input__email_error : '',
        )}
      >
        <input
          type="text"
          placeholder=" "
          id="pincode"
          name="pincode"
          value={pincode}
          onChange={setForm}
          ref={pinFeild}
          autoComplete="off"
        />
        <span className={stylesForm.label}>Pin code here …</span>
        <span className={stylesForm.border} />
        {pinError.err !== '' && (
          <span className={stylesForm.email__error}>{pinError.err}</span>
        )}
      </label>
      <Navigation navigation={navigation} isValid={isValid} prevDisable />
    </>
  );
};

export default PincodeStep;
