import React, { useState, useEffect } from 'react';
import styles from './FilterNav.scss';
import { gamesList } from '../../../data/gamesList';

const images = require.context('../../images', true);

const FilterNav = props => {
  const { setFilterStatus, filterStatus, fetchApiHandler } = props;
  const [activeNav, useActiveNav] = useState('ALL');

  const gameFilter = abbr => {
    setFilterStatus({
      ...filterStatus,
      gameDate: abbr,
    });
    useActiveNav(abbr);
    fetchApiHandler();
  };

  return (
    <div className={styles.filter__nav}>
      <div className={styles.games__filter}>
        <div className={styles.games__filter__content}>
          {gamesList.map(game => {
            const allGames = game.abbr === '';
            // eslint-disable-next-line no-param-reassign
            game.abbr === '' ? (game.abbr = 'ALL') : null;
            // eslint-disable-next-line no-nested-ternary
            const gameActiveSrc =
              game.abbr === activeNav ? game.abbr + '-active' : game.abbr;
            return (
              <a
                key={game.id}
                role="button"
                tabIndex={0}
                className={styles.game}
                onClick={() => gameFilter(game.abbr)}
              >
                <p>{game.date && `${game.date}`}</p>
                <img src={images(`./${gameActiveSrc}.png`)} alt="" />
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FilterNav;
