import React from 'react';

import styles from './Form.scss';

const Navigation = props => {
  const { navigation, isValid, prevDisable } = props;
  const { previous, next } = navigation;

  return (
    <div className={styles.form__navigation}>
      <button onClick={previous} disabled={prevDisable}>
        Previous
      </button>
      <button onClick={next} disabled={!isValid}>
        Next
      </button>
      {!isValid ? (
        <span className={styles.required_feild}>
          Fill all the requiered feilds
        </span>
      ) : (
        <span className={styles.required_feild}>
          Press <span>Enter</span>
        </span>
      )}
    </div>
  );
};

export default Navigation;
