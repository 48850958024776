import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import { Input, Radio } from '../../../../../components/shared';

import styles from '../../../Forms.scss';
import stylesForm from '../../../../../components/shared/Form/Form.scss';

import completeIcon from '../../../../../images/complete-icon.png';
import backArrow from '../../../../../images/arrow.svg';
import * as ROUTES from '../../../../../constants/routes';

const SubmissionForm = ({ navigation, step, setForm, formData }) => {
  const { next, previous, go } = navigation;
  const restartForm = async () => {
    await setForm({
      target: {
        name: 'games',
        value: {},
      },
    });
    await setForm({
      target: {
        name: 'recipientName',
        value: '',
        type: 'text',
        checked: false,
      },
    });
    await setForm({
      target: {
        name: 'recipientEmail',
        value: '',
        type: 'text',
        checked: false,
      },
    });
    await setForm({
      target: {
        name: 'recipientPhone',
        value: '',
        type: 'text',
        checked: false,
      },
    });
    await setForm({
      target: {
        name: 'jobFunction',
        value: '',
        type: 'text',
        checked: false,
      },
    });
    await setForm({
      target: {
        name: 'reqTypeExt',
        value: '',
        type: 'text',
        checked: false,
      },
    });
    await setForm({
      target: {
        name: 'parking',
        value: '',
        type: 'text',
        checked: false,
      },
    });
    await setForm({
      target: {
        name: 'satTruck',
        value: '',
        type: 'text',
        checked: false,
      },
    });
    await setForm({
      target: {
        name: 'notes',
        value: '',
        type: 'text',
        checked: false,
      },
    });
    await setForm({
      target: {
        name: 'approved',
        value: '',
        type: 'text',
        checked: false,
      },
    });
    go('recipientCompanyStep');
  };
  return (
    <>
      <div className={styles.form__submission}>
        <img
          className={styles.form__submission__icon}
          src={completeIcon}
          alt=""
        />
        <h2>SUBMISSION COMPLETE!</h2>
        <h4>Back to Home</h4>
        <button onClick={restartForm}>
          <img src={backArrow} alt="" />
        </button>
      </div>
    </>
  );
};

export default SubmissionForm;
