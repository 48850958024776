import React, { useState, useEffect } from 'react';

import {
  Input,
  Radio,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

import styles from '../../../Forms.scss';

const ReasonForRequestStep = ({ setForm, formData, navigation, step }) => {
  const { reasonForRequest } = formData;
  const { next, previous } = navigation;
  const [isValid, setIsValid] = useState(false);

  function keyDownHandler(e) {
    e.key === 'Enter' && isValid ? next() : null;
    e.key === 'Backspace' && step.id !== 'stepOne' ? previous() : null;
  }

  KeyboardNav(step, isValid, navigation);

  useEffect(() => {
    if (reasonForRequest !== '') {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [formData, isValid]);
  return (
    <>
      <h4>
        <span>6</span> Reason for Request *
      </h4>
      <Input
        name="reasonForRequest"
        placeholder="Type your answer here …"
        type="textarea"
        value={reasonForRequest}
        onChange={setForm}
      />
      <Navigation navigation={navigation} isValid={isValid} />
    </>
  );
};

export default ReasonForRequestStep;
