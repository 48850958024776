import React from 'react';

import TicketsRowItems from './TicketsRowItems';
import CredentialsRowItems from './CredentialsRowItems';
import ParkingsRowItems from './ParkingsRowItems';

function BoxRowItemBase(props) {
  const { item, page } = props;

  return (
    <>
      {page === 'tickets' && <TicketsRowItems item={item} page={page} />}
      {page === 'credentials' && (
        <CredentialsRowItems item={item} page={page} />
      )}
      {page === 'parkings' && <ParkingsRowItems item={item} page={page} />}
    </>
  );
}

export default BoxRowItemBase;
