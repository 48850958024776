import React from 'react';

import MultiStepFormBase from './MultiStepForm';

import styles from '../../Forms.scss';

const MultiStepForm = () => {
  return (
    <div className={styles.form}>
      <MultiStepFormBase />
    </div>
  );
};

export default MultiStepForm;
