import React from 'react';
import { Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

import logo from '../../images/logo.png';

import styles from './Home.scss';

const HomeBase = () => {
  return (
    <div className={styles.home}>
      <div className={styles.home__logo}>
        <img src={logo} alt="" />
        <button>
          <Link to={ROUTES.LOGIN}>Admin login</Link>
        </button>
      </div>
      <div className={styles.home__tiles}>
        <div className={styles.home__tiles__tickets}>
          <Link to={ROUTES.TICKETS}>
            <div className={styles.home__tiles__tickets__content}>
              <h2>Tickets</h2>
              <p>Go to the Ticket Request Form</p>
            </div>
          </Link>
        </div>
        <div className={styles.home__tiles__parking}>
          <Link to={ROUTES.PARKING}>
            <div className={styles.home__tiles__parking__content}>
              <h2>Parking</h2>
              <p>Go to the Parking Request Form</p>
            </div>
          </Link>
        </div>
        <div className={styles.home__tiles__credentials}>
          <Link to={ROUTES.CREDENTIALS}>
            <div className={styles.home__tiles__credentials__content}>
              <h2>Credentials</h2>
              <p>Go to the Credentials Request Form</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomeBase;
