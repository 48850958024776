import React, { useState, useEffect, createRef, useRef } from 'react';
import { Link } from 'react-router-dom';

import { AuthContext } from '../../contexts/AuthContext';
import * as ROUTES from '../../constants/routes';

import logo from '../../images/logo.png';

import styles from './Admin.scss';

const AdminBase = props => {
  const { auth, history } = props;

  useEffect(() => {
    const jwtAuth = JSON.parse(localStorage.getItem('jwtAuth'));

    !jwtAuth ? history.push(ROUTES.LOGIN) : null;
  });

  return (
    <div className={styles.admin}>
      <div className={styles.admin__logo}>
        <img src={logo} alt="" />
        <button onClick={() => auth.logout()}>Log Out</button>
      </div>
      <div className={styles.admin__tiles}>
        <div className={styles.admin__tiles__tickets}>
          <Link to={ROUTES.ADMIN_TICKETS}>
            <div className={styles.admin__tiles__tickets__content}>
              <h2>tickets</h2>
              <p>Go to the Ticket Request Form</p>
            </div>
          </Link>
        </div>
        <div className={styles.admin__tiles__parking}>
          <Link to={ROUTES.ADMIN_PARKING}>
            <div className={styles.admin__tiles__parking__content}>
              <h2>Parking</h2>
              <p>Go to the Parking Request Form</p>
            </div>
          </Link>
        </div>
        <div className={styles.admin__tiles__credentials}>
          <Link to={ROUTES.ADMIN_CREDENTIALS}>
            <div className={styles.admin__tiles__credentials__content}>
              <h2>Credentials</h2>
              <p>Go to the Credentials Request Form</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default React.forwardRef((props, ref) => (
  <AuthContext.Consumer>
    {auth => <AdminBase {...props} auth={auth} ref={ref} />}
  </AuthContext.Consumer>
));
