import React, { useState, useEffect } from 'react';

import {
  Checkbox,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

import styles from '../../../Forms.scss';

const GamesStep = ({ setForm, formData, navigation, step, allGames }) => {
  const { games } = formData;
  const [isValid, setIsValid] = useState(false);
  KeyboardNav(step, isValid, navigation);

  useEffect(() => {
    Object.keys(games).every(game => !games[game])
      ? setIsValid(false)
      : setIsValid(true);
  }, [formData, isValid]);

  return (
    <>
      <h4>
        <span>11</span> Please select which game(s) your request is for: *
      </h4>
      {/* <h5>Choose as many as you like</h5> */}

      <div className={styles.form__multiple}>
        {allGames?.map(game => {
          const { alias, label } = game;
          const value = `games.${alias}`;
          return (
            <Checkbox
              id={alias}
              key={alias}
              label={label}
              value={value}
              onChange={setForm}
              games={games}
            />
          );
        })}
      </div>
      <Navigation navigation={navigation} isValid={isValid} />
    </>
  );
};

export default GamesStep;
