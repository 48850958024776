import React, { useEffect, useState, createRef } from 'react';
import cx from 'classnames';

import {
  Input,
  Radio,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';
import { credsTypeZoneList } from '../../../../../../data/credsTypeList';
import styles from '../../../Forms.scss';
import stylesForm from '../../../../../components/shared/Form/Form.scss';
import imgZone1 from '../../../../../images/zone1.png';
import imgZone2 from '../../../../../images/zone2.png';
import imgZone3 from '../../../../../images/zone3.png';
import imgZone4 from '../../../../../images/zone4.png';

const CredTypeZoneStep = ({ setForm, formData, navigation, step }) => {
  const { customReqType } = formData;
  const { next, previous } = navigation;
  const [isValid, setIsValid] = useState(false);

  const hiddencustomReq = createRef();
  const custominput = createRef();
  KeyboardNav(step, isValid, navigation);

  useEffect(() => {
    if (customReqType !== '') {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [isValid]);

  return (
    <div>
      <div className={styles.form__req_type_zone}>
        <div className={styles.form__req_type_zone__item}>
          <img src={imgZone1} alt="" />
        </div>
        <div className={styles.form__req_type_zone__item}>
          <img src={imgZone2} alt="" />
        </div>
        <div className={styles.form__req_type_zone__item}>
          <img src={imgZone3} alt="" />
        </div>
        <div className={styles.form__req_type_zone__item}>
          <img src={imgZone4} alt="" />
        </div>
      </div>
      <h4>
        <span>5.1</span> In which Zone are you requesting Pregame Sideline
        passes?*
      </h4>

      <div className={styles.form__multiple}>
        {credsTypeZoneList.map(typeItem => {
          const { id, label } = typeItem;

          return (
            <Radio
              id={id}
              key={id}
              label={label}
              name="customReqType"
              value={id}
              checked={customReqType === id}
              onChange={setForm}
              type="radio"
            />
          );
        })}
        <input
          id="hiddencustomReq"
          key="hiddencustomReq"
          label="hiddencustomReq"
          name="customReqType"
          className={stylesForm.radio__input}
          value="customReq"
          onChange={setForm}
          type="radio"
          ref={hiddencustomReq}
        />
      </div>
      <Navigation navigation={navigation} isValid={customReqType !== ''} />
    </div>
  );
};

export default CredTypeZoneStep;
