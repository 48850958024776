import React, { useState, useEffect } from 'react';

import {
  Input,
  Radio,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';
import { parkingLotLocatoin } from '../../../../../../data/parkingLotLocation';

import styles from '../../../Forms.scss';

const PreferredParkingStep = ({ setForm, formData, navigation, step }) => {
  const { preferredParking } = formData;
  const { next, previous } = navigation;
  const [isValid, setIsValid] = useState(true);

  KeyboardNav(step, isValid, navigation);
  useEffect(() => {}, [formData, isValid]);
  return (
    <>
      <h4>
        <span>7</span> Do you have a preferred parking lot location?
      </h4>

      <div className={styles.form__multiple}>
        {parkingLotLocatoin.map(item => {
          const { id, label } = item;

          return (
            <Radio
              id={id}
              key={id}
              label={label}
              name="preferredParking"
              value={id}
              checked={preferredParking === id}
              onChange={setForm}
              type="radio"
            />
          );
        })}
      </div>
      <Navigation navigation={navigation} isValid={isValid} />
    </>
  );
};

export default PreferredParkingStep;
