import React from 'react';
import styles from './BoxRow.scss';
import { pageContext } from '../../../contexts/PageContext';
import { BoxRowItem } from '../index';

function BoxRowBase(props) {
  return (
    <pageContext.Consumer>
      {page => <BoxRowItem {...props} page={page} />}
    </pageContext.Consumer>
  );
}

export default BoxRowBase;
