import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { Modal } from '../../../../../components/shared';

import { gamesList } from '../../../../../../data/gamesList';
import { parkingLotLocatoin } from '../../../../../../data/parkingLotLocation';
import { parkingsTypeList } from '../../../../../../data/parkingsTypeList';

import styles from '../../../Forms.scss';

const CREATE_PARKING = gql`
  mutation CreateParking($parkingInput: ParkingInput!) {
    createParking(parkingInput: $parkingInput) {
      id
    }
  }
`;

const ReviewForm = ({ formData, navigation, allGames }) => {
  const {
    employeeName,
    employeeEmail,
    games,
    reqType,
    customReqType,
    numberOfParkings,
    reasonForRequest,
    preferredParking,
    notes,
    recipientName,
    recipientCompany,
  } = formData;

  const { next, previous, go } = navigation;

  const [modalData, setModalData] = useState({
    openModal: false,
    text: '',
    onConfirm: null,
  });
  const [createParkingError, setTreateParkingError] = useState();

  const [gamesArray, setGamesArray] = useState([]);
  const [gamesArrayAbbr, setGamesArrayAbbr] = useState([]);

  const [createParking, { loading }] = useMutation(CREATE_PARKING, {
    onCompleted: () => {
      setTreateParkingError(null);
      next();
    },
    onError: error => {
      console.log(error);
      setTreateParkingError('Something went wrong! Please try again later.');
    },
  });

  const submitModal = () => {
    setModalData({
      openModal: true,
      text: 'Submit Form',
      onConfirm: handleSubmit,
    });
  };

  const handleSubmit = () => {
    createParking({
      variables: {
        parkingInput: {
          employeeEmail,
          employeeName,
          games: gamesArrayAbbr,
          notes,
          reqType,
          customReqType,
          numberOfParkings,
          reasonForRequest,
          preferredParking,
          recipientName,
          recipientCompany,
        },
      },
    });
  };

  useEffect(() => {
    let _gamesArray = [];
    let _gamesArrayAbbr = [];

    Object.keys(games).forEach(keyName => {
      if (games.all2020) {
        _gamesArray = [];
        _gamesArrayAbbr = [];
        _gamesArray.push(allGames[0]);
        allGames.forEach(game =>
          game.alias !== 'all2020' ? _gamesArrayAbbr.push(game.abbr) : null,
        );
      } else if (keyName !== 'all2020' && games[keyName]) {
        allGames.forEach(item => {
          if (keyName === item.alias) {
            _gamesArray.push(item);
            _gamesArrayAbbr.push(item.abbr);
          }
        });
      }
    });

    setGamesArray(_gamesArray);
    setGamesArrayAbbr(_gamesArrayAbbr);
  }, [loading]);
  return (
    <>
      <div className={styles.form__review}>
        <h2>Review</h2>
        {employeeName !== '' && (
          <div className={styles.form__review__item}>
            <h4>Your Name *</h4>
            <h2>{employeeName}</h2>
            <button onClick={() => go('employeeNameStep')}>Edit</button>
          </div>
        )}
        {employeeEmail !== '' && (
          <div className={styles.form__review__item}>
            <h4>Your Email Address *</h4>
            <h2>{employeeEmail}</h2>
            <button onClick={() => go('employeeEmailStep')}>Edit</button>
          </div>
        )}
        {gamesArray !== {} && (
          <div
            className={cx(
              styles.form__review__item,
              styles.form__review__item__list,
            )}
          >
            <h4>Game(s) Requested *</h4>
            <button onClick={() => go('gamesStep')}>Edit</button>
            <div className={styles.form__review__item__list__item}>
              {gamesArray.map(item => (
                <span key={item.id}>{item.label}</span>
              ))}
            </div>
          </div>
        )}
        {reqType !== '' && (
          <div className={styles.form__review__item}>
            <h4>Type of Request *</h4>
            {reqType === 'customReq' ? (
              <h2>{customReqType}</h2>
            ) : (
              parkingsTypeList.map(req => {
                return req.id === reqType ? (
                  <h2 key={req.id}>{req.label}</h2>
                ) : null;
              })
            )}
            <button onClick={() => go('parkingTypeStep')}>Edit</button>
          </div>
        )}
        {numberOfParkings !== '' && (
          <div className={styles.form__review__item}>
            <h4>Number of Parking Passes *</h4>
            <h2>{numberOfParkings}</h2>
            <button onClick={() => go('numberOfParkingsStep')}>Edit</button>
          </div>
        )}

        {reasonForRequest !== '' && (
          <div className={styles.form__review__item}>
            <h4>Reason for Request *</h4>
            <h2>{reasonForRequest}</h2>
            <button onClick={() => go('reasonForRequestStep')}>Edit</button>
          </div>
        )}
        {preferredParking !== '' && (
          <div className={styles.form__review__item}>
            <h4>Preferred Parking Lot Location *</h4>
            {parkingLotLocatoin.map(preferredParkingItem => {
              return preferredParkingItem.id === preferredParking ? (
                <h2 key={preferredParkingItem.id}>
                  {preferredParkingItem.label}
                </h2>
              ) : null;
            })}
            <button onClick={() => go('preferredParkingStep')}>Edit</button>
          </div>
        )}
        {notes !== '' && (
          <div className={styles.form__review__item}>
            <h4>Notes</h4>
            <h2>{notes}</h2>
            <button onClick={() => go('notesStep')}>Edit</button>
          </div>
        )}

        {recipientName !== '' && (
          <div className={styles.form__review__item}>
            <h4>Recipient Name(s) *</h4>
            <h2>{recipientName}</h2>
            <button onClick={() => go('recipientNameStep')}>Edit</button>
          </div>
        )}
        {recipientCompany !== '' && (
          <div className={styles.form__review__item}>
            <h4>Recipient Company</h4>
            <h2>{recipientCompany}</h2>
            <button onClick={() => go('recipientCompanyStep')}>Edit</button>
          </div>
        )}
      </div>
      <div className={styles.form__navigation}>
        <button onClick={previous}>Previous</button>
        <button onClick={() => submitModal()}>Submit</button>
      </div>
      <Modal canConfirm setModalData={setModalData} modalData={modalData}>
        {modalData.text}
      </Modal>
    </>
  );
};

export default ReviewForm;
