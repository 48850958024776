/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, createRef, useRef } from 'react';
import cx from 'classnames';
import useDynamicRefs from 'use-dynamic-refs';

import styles from './Modal.scss';

const ModalBase = props => {
  const { children, canConfirm, setModalData, modalData } = props;
  const { recipient, item } = modalData;

  const [isValid, setIsValid] = useState();
  const [recipientRefs, setRecipientRefs] = useState();
  const noteFeild = createRef();
  const noteAFeild = createRef();
  const noteBFeild = createRef();
  const noteCFeild = createRef();

  const [openModal, setOpenModal] = useState(false);

  function handleChange(i, field, id, event) {
    const values = [...recipientRefs];

    // const values = recipientRefs
    //   ? [...recipientRefs]
    //   : [{ section: '', row: '', account: '', id }];
    // console.log('values', values);
    values[i].id = id;

    values[i][field] = event.target.value;
    // if (values[i]) {
    // } else {
    //   values.push({ section: '', row: '', account: '', id });
    //   values[values.length - 1][field] = event.target.value;
    // }
    setRecipientRefs(values);
  }
  function inputValid() {
    if (noteAFeild === null || noteAFeild?.current?.value === '') {
      setIsValid(false);
    } else if (
      (item?.reqType === 'pregameSideline' && noteBFeild === null) ||
      noteBFeild?.current?.value === ''
    ) {
      setIsValid(false);
    } else if (
      (item?.numberOfParkings && noteBFeild === null && noteCFeild === null) ||
      noteBFeild?.current?.value === '' ||
      noteCFeild?.current?.value === ''
    ) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }

  useEffect(() => {
    if (modalData.openModal) {
      setOpenModal(true);
      if (!recipientRefs) {
        const values = [];
        recipient?.forEach(r => {
          values.push({
            section: r.section,
            row: r.row,
            account: r.account,
            id: r.id,
          });
        });
        setRecipientRefs(values);
      }
    } else {
      setRecipientRefs(null);
      setOpenModal(false);
    }
  }, [modalData, recipientRefs, isValid]);

  return (
    <div className={cx(styles.modal, openModal ? styles.open : '')}>
      <section className={styles.modal__content}>
        <div className={styles.modal__content__text}>
          <h2>{children}</h2>
          <button
            className={styles.modal__close}
            onClick={() => {
              setOpenModal(false);
              setModalData({ openModa: false });
            }}
          >
            &#10005;
          </button>
        </div>
        <div className={styles.modal__content__actions}>
          {modalData.note && (
            <textarea
              className={styles.modal__content__note}
              ref={noteFeild}
              placeholder="Notes"
            />
          )}
          {modalData.noteA && (
            <div className={styles['modal__content__note--a']}>
              <span>{modalData.noteA}</span>
              <input type="text" ref={noteAFeild} onChange={inputValid} />
            </div>
          )}
          {modalData.noteB && (
            <div className={styles['modal__content__note--b']}>
              <span>{modalData.noteB}</span>
              <input type="text" ref={noteBFeild} onChange={inputValid} />
            </div>
          )}
          {modalData.noteC && (
            <div className={styles['modal__content__note--b']}>
              <span>{modalData.noteC}</span>
              <input type="text" ref={noteCFeild} onChange={inputValid} />
            </div>
          )}
          <button
            className={cx(styles.btn, styles.btn__close)}
            onClick={() => {
              setOpenModal(false);
              setModalData({ openModa: false });
            }}
          >
            Close
          </button>
          {canConfirm && item?.status !== 'fulfilled' && (
            <button
              className={styles.btn}
              onClick={() =>
                modalData.onConfirm({
                  noteFeild: noteFeild?.current?.value,
                  noteAFeild: noteAFeild?.current?.value,
                  noteBFeild: noteBFeild?.current?.value,
                  noteCFeild: noteCFeild?.current?.value,
                  recipientRefs,
                })
              }
              disabled={item?.status === 'approved' && !isValid}
            >
              {item?.status === 'approved' ? 'Fulfill' : 'Confirm'}
            </button>
          )}
        </div>
      </section>
    </div>
  );
};

export default ModalBase;
