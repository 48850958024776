/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { Modal } from '../../../../../components/shared';
import { positionList } from '../../../../../../data/positionList';
import { jobFunctionList } from '../../../../../../data/jobFunction';

import styles from '../../../Forms.scss';
import { credsTypeListExt } from '../../../../../../data/credsTypeListExt';

const CREATE_TICKET = gql`
  mutation CreateCred($credInput: CredInput!) {
    createCred(credInput: $credInput) {
      employeeName
    }
  }
`;
const ReviewForm = ({ formData, navigation, allGames }) => {
  const {
    recipientCompany,
    position,
    employeeName,
    employeePhone,
    employeeEmail,
    games,
    customReqType,
    notes,
    recipientName,
    recipientEmail,
    recipientPhone,
    address,
    city,
    state,
    zipCode,
    jobFunction,
    reqTypeExt,
    parking,
    satTruck,
  } = formData;

  const { next, previous, go } = navigation;
  const [createCredError, setTreateCredError] = useState();
  const [createCred] = useMutation(CREATE_TICKET, {
    onCompleted: () => {
      setTreateCredError(null);
      next();
    },
    onError: error => {
      console.log(error);
      setTreateCredError('Something went wrong! Please try again later.');
    },
  });

  const [modalData, setModalData] = useState({
    openModal: false,
    text: '',
    onConfirm: null,
  });
  const [gamesArray, setGamesArray] = useState([]);
  const [gamesArrayAbbr, setGamesArrayAbbr] = useState([]);

  const submitModal = () => {
    setModalData({
      openModal: true,
      text: 'Submit Form',
      onConfirm: handleSubmit,
    });
  };

  const handleSubmit = () => {
    createCred({
      variables: {
        credInput: {
          employeeName,
          employeeEmail,
          recipientCompany,
          employeePhone,
          numberOfCreds: '1',
          position,
          games: gamesArrayAbbr,
          customReqType,
          notes,
          recipientName,
          recipientEmail,
          recipientPhone,
          address,
          city,
          state,
          zipCode,
          jobFunction,
          reqTypeExt,
          parking,
          satTruck,
          credType: 'external',
        },
      },
    });
  };

  useEffect(() => {
    let _gamesArray = [];
    let _gamesArrayAbbr = [];
    Object.keys(games).forEach(keyName => {
      if (games.all2020) {
        _gamesArray = [];
        _gamesArrayAbbr = [];
        _gamesArray.push(allGames[0]);
        allGames.forEach(game =>
          game.alias !== 'all2020' ? _gamesArrayAbbr.push(game.abbr) : null,
        );
      } else if (keyName !== 'all2020' && games[keyName]) {
        allGames.forEach(item => {
          if (keyName === item.alias) {
            _gamesArray.push(item);
            _gamesArrayAbbr.push(item.abbr);
          }
        });
      }
    });
    setGamesArray(_gamesArray);
    setGamesArrayAbbr(_gamesArrayAbbr);
  }, [formData]);
  return (
    <>
      <div className={styles.form__review}>
        <h2>Review</h2>
        {recipientCompany !== '' && (
          <div className={styles.form__review__item}>
            <h4>Recipient Company</h4>
            <h2>{recipientCompany}</h2>
            <button onClick={() => go('recipientCompanyStep')}>Edit</button>
          </div>
        )}
        {position !== '' && (
          <div className={styles.form__review__item}>
            <h4>Position *</h4>
            {positionList.map(item => {
              return item.id === position ? (
                <h2 key={item.id}>
                  {item.id === 'other'
                    ? 'SPECIFIED IN SPECIAL REQUEST/COMMENTS SECTION'
                    : item.label}
                </h2>
              ) : null;
            })}
            <button onClick={() => go('positionStep')}>Edit</button>
          </div>
        )}
        {employeeName !== '' && (
          <div className={styles.form__review__item}>
            <h4>Your Name *</h4>
            <h2>{employeeName}</h2>
            <button onClick={() => go('employeeNameStep')}>Edit</button>
          </div>
        )}
        {employeeEmail !== '' && (
          <div className={styles.form__review__item}>
            <h4>Your Email Address *</h4>
            <h2>{employeeEmail}</h2>
            <button onClick={() => go('employeeEmailStep')}>Edit</button>
          </div>
        )}
        {employeePhone !== '' && (
          <div className={styles.form__review__item}>
            <h4>Your Phone Number *</h4>
            <h2>{employeePhone}</h2>
            <button onClick={() => go('employeePhoneStep')}>Edit</button>
          </div>
        )}
        {address !== '' && (
          <div className={styles.form__review__item}>
            <h4>address</h4>
            <h2>{address}</h2>
            <button onClick={() => go('addressStep')}>Edit</button>
          </div>
        )}
        {city !== '' && (
          <div className={styles.form__review__item}>
            <h4>city</h4>
            <h2>{city}</h2>
            <button onClick={() => go('cityStep')}>Edit</button>
          </div>
        )}
        {state !== '' && (
          <div className={styles.form__review__item}>
            <h4>state</h4>
            <h2>{state}</h2>
            <button onClick={() => go('stateStep')}>Edit</button>
          </div>
        )}
        {zipCode !== '' && (
          <div className={styles.form__review__item}>
            <h4>Zip Code</h4>
            <h2>{zipCode}</h2>
            <button onClick={() => go('zipCodeStep')}>Edit</button>
          </div>
        )}
        {gamesArray !== {} && (
          <div
            className={cx(
              styles.form__review__item,
              styles.form__review__item__list,
            )}
          >
            <h4>Game(s) Requested *</h4>
            <button onClick={() => go('gamesStep')}>Edit</button>
            <div className={styles.form__review__item__list__item}>
              {gamesArray.map(item => (
                <span key={item.abbr}>{item.label}</span>
              ))}
            </div>
          </div>
        )}
        {recipientName !== '' && (
          <div className={styles.form__review__item}>
            <h4>Credential Recipient Name *</h4>
            <h2>{recipientName}</h2>
            <button onClick={() => go('recipientNameStep')}>Edit</button>
          </div>
        )}
        {recipientEmail !== '' && (
          <div className={styles.form__review__item}>
            <h4>Credential Recipient Email Address *</h4>
            <h2>{recipientEmail}</h2>
            <button onClick={() => go('recipientEmailStep')}>Edit</button>
          </div>
        )}
        {recipientPhone !== '' && (
          <div className={styles.form__review__item}>
            <h4>Credential Recipient Mobile Phone Number</h4>
            <h2>{recipientPhone}</h2>
            <button onClick={() => go('recipientPhoneStep')}>Edit</button>
          </div>
        )}
        {jobFunction !== '' && (
          <div className={styles.form__review__item}>
            <h4>Credential Recipient Job Function *</h4>
            {jobFunctionList.map(item => {
              return item.id === jobFunction ? (
                <h2 key={item.id}>
                  {item.id === 'other'
                    ? 'SPECIFIED IN SPECIAL REQUEST/COMMENTS SECTION'
                    : item.label}
                </h2>
              ) : null;
            })}
            <button onClick={() => go('jobFunctionStep')}>Edit</button>
          </div>
        )}
        {reqTypeExt !== '' && (
          <div className={styles.form__review__item}>
            <h4>Type of Request *</h4>
            {reqTypeExt === 'customReq' ? (
              <h2>{customReqType}</h2>
            ) : (
              credsTypeListExt.map(req => {
                return req.id === reqTypeExt ? (
                  <h2 key={req.id}>{req.label}</h2>
                ) : null;
              })
            )}
            <button onClick={() => go('reqTypeExtStep')}>Edit</button>
          </div>
        )}
        {parking !== '' && (
          <div className={styles.form__review__item}>
            <h4>Parking *</h4>
            <h2>{parking === 'yesParking' ? 'yes' : 'No'}</h2>
            <button onClick={() => go('parkingStep')}>Edit</button>
          </div>
        )}
        {satTruck !== '' && (
          <div className={styles.form__review__item}>
            <h4>Sat Truck *</h4>
            <h2>{satTruck === 'yesTruck' ? 'yes' : 'No'}</h2>
            <button onClick={() => go('satTruckStep')}>Edit</button>
          </div>
        )}

        {notes !== '' && (
          <div className={styles.form__review__item}>
            <h4>Special Request/Comments (if necessary):</h4>
            <h2>{notes}</h2>
            <button onClick={() => go('notesStep')}>Edit</button>
          </div>
        )}
      </div>
      <div className={styles.form__navigation}>
        <button onClick={previous}>Previous</button>
        <button
          onClick={() => {
            submitModal();
          }}
        >
          Submit
        </button>
      </div>
      <Modal canConfirm setModalData={setModalData} modalData={modalData}>
        {!createCredError ? modalData.text : ''}
        {createCredError || ''}
      </Modal>
    </>
  );
};

export default ReviewForm;
