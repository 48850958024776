/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import { Parser } from 'json2csv';
import flatten from 'flat';

const today = new Date();
const date =
  new Date().getFullYear() +
  '-' +
  (new Date().getMonth() + 1) +
  '-' +
  new Date().getDate();

export const exportCsv = async (page, list, status) => {
  if (!list) return;

  const ticketsFields = [
    'id',
    'employeeName',
    'employeeEmail',
    'games.abbr',
    'reqType',
    'customReqType',
    'numberOfTickets',
    'seatingPreference',
    'notes',
    'reasonForRequest',
    'recipientName',
    'recipientEmail',
    'recipientPhone',
    'recipientCompany',
    'accountNum',
    'delivery',
    'status',
    'createdAt',
  ];
  const CredsFields = [
    '_id',
    'seq',
    'employeeName',
    'employeeEmail',
    'games',
    'reqType',
    'customReqType',
    'numberOfTickets',
    'seatingPreference',
    'notes',
    'reasonForRequest',
    'recipientCompany',
    'recipientInfo._id',
    'recipientInfo.name',
    'recipientInfo.email',
    'recipientInfo.phone',
    'recipientInfo.section',
    'recipientInfo.row',
    'recipientInfo.account',
    'delivery',
    'status',
    'dateRequested',
    'fulfillmentId._id',
    'fulfillmentId.zone',
    'fulfillmentId.number',
  ];
  const CredsExtFields = [
    '_id',
    'employeeName',
    'employeePhone',
    'employeeEmail',
    'games',
    'customReqType',
    'notes',
    'recipientName',
    'recipientCompany',
    'recipientEmail',
    'recipientPhone',
    'dateRequested',
    'status',
    'fulfillmentId.number',
    'position',
    'address',
    'city',
    'state',
    'zipCode',
    'jobFunction',
    'reqTypeExt',
    'parking',
    'satTruck',
  ];
  const parkingFields = [
    'id',
    'employeeName',
    'employeeEmail',
    'games.abbr',
    'reqType',
    'customReqType',
    'numberOfParkings',
    'preferredParking',
    'notes',
    'reasonForRequest',
    'recipientName',
    'recipientCompany',
    'accountNum',
    'lotLocation',
    'passNumber',
    'status',
    'createdAt',
  ];
  // const filename = `comp-${page}-requests-${date}`;
  const fields =
    page === 'tickets'
      ? ticketsFields
      : page === 'creds'
      ? CredsFields
      : page === 'parkings'
      ? parkingFields
      : page === 'credsExt'
      ? CredsExtFields
      : parkingFields;

  const opts = {
    fields,
    unwind: ['games'],
    unwindBlank: true,
  };
  const arr = status ? await list.filter(x => x.status === status) : list;
  try {
    const parser = new Parser(opts);
    const csv = parser.parse(arr);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    return url;
  } catch (err) {
    console.error(err);
  }
};
