import React, { useState, useEffect } from 'react';

import {
  Input,
  Radio,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

import styles from '../../../Forms.scss';
import stylesForm from '../../../../../components/shared/Form/Form.scss';

const RecipientNameStep = ({ setForm, formData, navigation, step }) => {
  const { recipientName } = formData;
  const { next, previous } = navigation;
  const [isValid, setIsValid] = useState(false);

  KeyboardNav(step, isValid, navigation);

  useEffect(() => {
    if (recipientName !== '') {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [formData, isValid]);

  return (
    <>
      <h4>
        <span>9</span> Recipient Name(s) *
      </h4>
      <h5>Separate with commas</h5>
      <Input
        name="recipientName"
        placeholder="Type your answer here …"
        value={recipientName}
        onChange={setForm}
      />
      <Navigation navigation={navigation} isValid={isValid} />
    </>
  );
};

export default RecipientNameStep;
