import React, { useState, useEffect } from 'react';

import {
  Input,
  Radio,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

import styles from '../../../Forms.scss';
import stylesForm from '../../../../../components/shared/Form/Form.scss';

const approvedItems = [
  {
    id: 'approved',
    label: 'APPROVED',
  },
  {
    id: 'notApproved',
    label:
      "Oops! This request has not been reviewed by my Supervisor. I'll come back later.",
  },
];

const ApprovedStep = ({ setForm, formData, navigation, step }) => {
  const { approved } = formData;
  const { next, previous } = navigation;
  const [isValid, setIsValid] = useState(false);
  const [apprError, setApprError] = useState({ err: '' });

  KeyboardNav(step, isValid, navigation);

  useEffect(() => {
    if (approved !== '') {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    if (approved === 'notApproved') {
      setApprError({
        err:
          'You must confirm that your Supervisor has approved this request prior to submitting. ',
      });
      setIsValid(false);
    } else {
      setApprError({ err: '' });
    }
  }, [formData, isValid]);

  return (
    <>
      <h4>
        <span>7</span> By clicking APPROVED, you’re confirming that this request
        has been reviewed and approved by your Supervisor. *
      </h4>

      <div className={styles.form__multiple__col1}>
        {approvedItems.map(item => {
          const { id, label } = item;

          return (
            <Radio
              id={id}
              key={id}
              label={label}
              name="approved"
              value={id}
              checked={approved === id}
              onChange={setForm}
              type="radio"
              err={apprError}
            />
          );
        })}
        {apprError.err !== '' && (
          <span className={styles.input__error}>{apprError.err}</span>
        )}
      </div>
      <Navigation navigation={navigation} isValid={isValid} />
    </>
  );
};

export default ApprovedStep;
