import React from 'react';
import cx from 'classnames';

import styles from './Form.scss';

const Checkbox = props => {
  const { label, value, games, id } = props;
  const gameItem = games[`${id}`];
  const gameItemStatus =
    gameItem && games.all2020 !== true ? styles['checkbox--selected'] : '';
  const gameAll2019 =
    value === 'games.all2020' && games.all2020 === true
      ? styles['checkbox--selected']
      : '';
  return (
    <div className={cx(styles.checkbox, gameItemStatus, gameAll2019)}>
      <label htmlFor={id} className={styles.checkbox__label}>
        {label}
      </label>
      <input
        className={styles.checkbox__input}
        id={id}
        type="checkbox"
        name={value}
        checked={gameItem === true}
        // disabled={games.all2020 === true}
        {...props}
      />
    </div>
  );
};

export default Checkbox;
