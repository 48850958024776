import React, { useState, useEffect, createRef, useRef } from 'react';
import cx from 'classnames';

import {
  Button,
  Input,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

import styles from '../../../Forms.scss';
import stylesForm from '../../../../../components/shared/Form/Form.scss';

const RecipientPhoneStep = ({ setForm, formData, navigation, step }) => {
  const { recipientPhone } = formData;
  const { next, previous } = navigation;
  const [isValid, setIsValid] = useState(true);
  const [emailError, setEmailError] = useState({ err: '' });

  const phoneFeild = createRef();

  const isNumericInput = event => {
    const key = event.keyCode;
    return (
      (key >= 48 && key <= 57) || // Allow number line
      (key >= 96 && key <= 105) // Allow number pad
    );
  };

  const isModifierKey = event => {
    const key = event.keyCode;
    return (
      event.shiftKey === true ||
      key === 35 ||
      key === 36 || // Allow Shift, Home, End
      (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      // Allow Ctrl/Command + A,C,V,X,Z
      ((event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
    );
  };
  const enforceFormat = event => {
    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if (!isNumericInput(event) && !isModifierKey(event)) {
      event.preventDefault();
    }
  };

  const formatToPhone = event => {
    if (isModifierKey(event)) {
      return;
    }

    // I am lazy and don't like to type things more than once
    const { target } = event;
    const input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
      target.value = `(${zip}) ${middle} - ${last}`;
    } else if (input.length > 3) {
      target.value = `(${zip}) ${middle}`;
    } else if (input.length > 0) {
      target.value = `(${zip}`;
    }
  };
  KeyboardNav(step, isValid, navigation);

  useEffect(() => {
    phoneFeild.current.addEventListener('keydown', enforceFormat);
    phoneFeild.current.addEventListener('keyup', formatToPhone);

    return () => {
      phoneFeild.current
        ? phoneFeild.current.removeEventListener('keydown', enforceFormat)
        : null;
      phoneFeild.current
        ? phoneFeild.current.removeEventListener('keyup', formatToPhone)
        : null;
    };
  }, [formData, isValid]);

  return (
    <>
      <h4>
        <span>14</span> Credential Recipient Mobile Phone Number
      </h4>
      <label htmlFor="recipientPhone" className={stylesForm.input}>
        <input
          type="text"
          placeholder=" "
          id="recipientPhone"
          name="recipientPhone"
          value={recipientPhone}
          onChange={setForm}
          ref={phoneFeild}
          autoComplete="off"
          maxLength="16"
        />
        <span className={stylesForm.label}>Type your answer here …</span>
        <span className={stylesForm.border} />
      </label>

      <Navigation navigation={navigation} isValid={isValid} />
    </>
  );
};

export default RecipientPhoneStep;
