import React from 'react';
import { withRouter } from 'react-router-dom';

import Header from '../../../components/Header';
import CredentialsPage from './Credentials';

import styles from './Credentials.scss';

const HeaderWithRouter = withRouter(Header);

const AdminCredentials = props => (
  <div className={styles.main}>
    <HeaderWithRouter />
    <CredentialsPage {...props} />
  </div>
);
export default AdminCredentials;
