import React, { useState, useEffect, createRef, useRef } from 'react';
import cx from 'classnames';

import {
  Input,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

import styles from '../../../Forms.scss';

const EmployeeNameStep = ({ setForm, formData, navigation, step }) => {
  const { employeeName } = formData;
  const [isValid, setIsValid] = useState(false);

  KeyboardNav(step, isValid, navigation);

  useEffect(() => {
    if (employeeName !== '') {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [formData, isValid]);

  return (
    <>
      <h4>
        <span>1</span> Your Name *
      </h4>
      <Input
        name="employeeName"
        placeholder="Enter your name …"
        value={employeeName}
        onChange={setForm}
      />
      <Navigation navigation={navigation} isValid={isValid} prevDisable />
    </>
  );
};

export default EmployeeNameStep;
