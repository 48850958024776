import React, { useState, useEffect, createRef, useRef } from 'react';
import cx from 'classnames';

import {
  Input,
  Radio,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

import styles from '../../../Forms.scss';
import stylesForm from '../../../../../components/shared/Form/Form.scss';
import CredentialsPage from '../../../../Admin/Credentials/Credentials';

export const yesNoParking = [
  {
    id: 'yesParking',
    label: 'Yes',
  },
  {
    id: 'noParking',
    label: 'No',
  },
];

const ParkingStep = ({ setForm, formData, navigation, step }) => {
  const { parking } = formData;
  const { next, previous } = navigation;
  const [isValid, setIsValid] = useState(false);

  KeyboardNav(step, parking !== '', navigation);
  useEffect(() => {
    if (parking !== '') {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [formData, isValid]);

  return (
    <>
      <h4>
        <span>17</span> Parking *
      </h4>
      <div className={styles.form__multiple}>
        {yesNoParking.map(parkingTypeItem => {
          const { id, label } = parkingTypeItem;

          return (
            <Radio
              id={id}
              key={id}
              label={label}
              name="parking"
              value={id}
              checked={parking === id}
              onChange={setForm}
              type="radio"
            />
          );
        })}
      </div>

      <Navigation navigation={navigation} isValid={isValid} />
    </>
  );
};

export default ParkingStep;
