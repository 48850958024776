export const seatingPreferenceItems = [
  { id: 'lowerLevel', label: 'Lower Level' },
  {
    id: 'upperLevel',
    label: 'Upper Level',
  },
  {
    id: 'clubLevel',
    label: 'Club Level',
  },
  {
    id: 'other',
    label: 'Other – Please specify in Special Request/Comments section.',
  },
];
