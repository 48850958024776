import React from 'react';
import cx from 'classnames';
import styles from './BoxHeader.scss';

function BoxHeaderBase(props) {
  const { title } = props;

  return (
    <div
      className={cx(styles.header, styles[`header--${title.toLowerCase()}`])}
    >
      <h4 className={styles.header__title}>{title}</h4>
      <div className={styles.header__separator} />
    </div>
  );
}
export default BoxHeaderBase;
