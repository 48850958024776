import React from 'react';
import { withRouter } from 'react-router-dom';

import Header from '../../../components/Header';
import ParkingsPage from './Parkings';

import styles from './Parkings.scss';

const HeaderWithRouter = withRouter(Header);

const AdminParkings = props => (
  <div className={styles.main}>
    <HeaderWithRouter />

    <ParkingsPage {...props} />
  </div>
);

export default AdminParkings;
