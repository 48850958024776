import React, { useState, useEffect } from 'react';

import {
  Radio,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';
import { seatingPreferenceItems } from '../../../../../../data/seatingPreferenceItems';
import styles from '../../../Forms.scss';

const SeatingPreferenceStep = ({ setForm, formData, navigation, step }) => {
  const { seatingPreference } = formData;
  const [isValid, setIsValid] = useState(false);

  KeyboardNav(step, isValid, navigation);

  useEffect(() => {
    if (seatingPreference !== '') {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [formData, isValid]);

  return (
    <>
      <h4>
        <span>6</span> Seating Preference (Subject to availability.) *
      </h4>

      <div className={styles.form__multiple__col1}>
        {seatingPreferenceItems.map(prefItem => {
          const { id, label } = prefItem;

          return (
            <Radio
              id={id}
              key={id}
              label={label}
              name="seatingPreference"
              value={id}
              checked={seatingPreference === id}
              onChange={setForm}
              type="radio"
            />
          );
        })}
      </div>
      <Navigation navigation={navigation} isValid={isValid} />
    </>
  );
};

export default SeatingPreferenceStep;
