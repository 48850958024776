import React, { useState, useEffect, createRef, useRef } from 'react';
import { useForm } from 'react-hooks-helper';
import cx from 'classnames';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { AuthContext } from '../../contexts/AuthContext';
import * as ROUTES from '../../constants/routes';

import styles from '../Forms/Forms.scss';
import stylesForm from '../../components/shared/Form/Form.scss';

import logo from '../../images/logo.png';
import {
  validateEmail,
  validatePass,
  emailOnFocus,
  passOnFocus,
} from '../../helpers/formHelpers';

const LOGIN = gql`
  query Login($login: UserInput!) {
    login(userInput: $login) {
      user {
        id
        role
        name
      }
      token
    }
  }
`;

const Login = props => {
  const { history } = props;
  const [isValid, setIsValid] = useState(false);
  const [emailError, setEmailError] = useState({ err: '' });
  const [passError, setPassError] = useState({ err: '' });
  const [loginError, setLoginError] = useState();

  const auth = React.useContext(AuthContext);

  const emailFeild = createRef();
  const passFeild = createRef();

  const [formData, setForm] = useForm({
    email: '',
    password: '',
  });

  const { email, password } = formData;

  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const [login, { loading }] = useLazyQuery(LOGIN, {
    onCompleted: res => {
      auth.login(
        res.login.token,
        res.login.user.id,
        res.login.user.role,
        res.login.user.name,
      );
      setLoginError(null);
    },
    onError: error => {
      // console.log("error",error)
      setLoginError(error.graphQLErrors[0].message);
    },
  });

  const prevEmail = usePrevious(email);
  const prevPass = usePrevious(password);

  const handleSubmit = (em, pass) => {
    login({
      variables: {
        login: {
          email: em,
          password: pass,
        },
      },
    });
  };

  useEffect(() => {
    if (auth.token) history.push(ROUTES.ADMIN_TICKETS);

    if (
      email !== '' &&
      password !== '' &&
      validateEmail(email) &&
      validatePass(password)
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }

    if (prevEmail && prevEmail.length > email.length && !validateEmail(email)) {
      setEmailError({ err: 'Email is not Valid!' });
    } else if (validateEmail(email)) {
      setEmailError({ err: '' });
    }

    if (
      prevPass &&
      prevPass.length > password.length &&
      !validatePass(password)
    ) {
      setPassError({ err: 'Password is not Valid! Minimum 8 characters.' });
    } else {
      setPassError({ err: '' });
    }

    if (loginError === 'User does not exist!' && !auth.token) {
      setEmailError({ err: 'User does not exist!' });
    } else {
      setEmailError({ err: '' });
    }

    if (
      loginError === 'Password is incorrect!' &&
      !auth.token &&
      validatePass(password)
    ) {
      setPassError({ err: 'Password is incorrect!' });
    }

    emailFeild?.current?.addEventListener('focusout', () =>
      emailOnFocus(email, setEmailError),
    );
    passFeild?.current?.addEventListener('focusout', () =>
      passOnFocus(password, setPassError),
    );

    return () => {
      emailFeild?.current?.removeEventListener('focusout', () =>
        emailOnFocus(email, setEmailError),
      );
      passFeild?.current?.removeEventListener('focusout', () =>
        passOnFocus(password, setPassError),
      );
    };
  }, [formData, isValid, auth, loading, loginError]);

  return (
    <div className={styles.forms}>
      <div className={styles.forms__header}>
        <div className={styles.forms__header__logo}>
          <img src={logo} alt="" />
        </div>
        <div className={styles.forms__header__content}>
          <h1>Login</h1>
          <h5>Please login to continue</h5>
        </div>
      </div>
      <div className={styles.forms__content}>
        <div className={styles.form}>
          <h4>Your Email Address *</h4>
          <label
            htmlFor="email"
            className={cx(
              stylesForm.input,
              emailError.err !== '' ? stylesForm.input__email_error : '',
            )}
          >
            <input
              type="text"
              placeholder=" "
              id="email"
              name="email"
              value={email}
              onChange={setForm}
              ref={emailFeild}
              autoComplete="off"
            />
            <span className={stylesForm.label}>Type your email here …</span>
            <span className={stylesForm.border} />
            {emailError.err !== '' && (
              <span className={stylesForm.email__error}>{emailError.err}</span>
            )}
          </label>

          <h4>Password *</h4>

          <label
            htmlFor="password"
            className={cx(
              stylesForm.input,
              passError.err !== '' ? stylesForm.input__email_error : '',
            )}
          >
            <input
              type="password"
              placeholder=" "
              id="password"
              name="password"
              value={password}
              onChange={setForm}
              ref={passFeild}
              autoComplete="off"
            />
            <span className={stylesForm.label}>Type your password here …</span>
            <span className={stylesForm.border} />
            {passError.err !== '' && (
              <span className={stylesForm.email__error}>{passError.err}</span>
            )}
          </label>
          <div className={styles.form__navigation}>
            <button
              onClick={() => handleSubmit(email, password)}
              disabled={!isValid}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
