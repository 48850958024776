import React from 'react';
import { withRouter } from 'react-router-dom';

import Header from '../../../components/Header';
import TicketsPage from './Tickets';

import styles from './Tickets.scss';

const HeaderWithRouter = withRouter(Header);

const AdminTickets = props => (
  <div className={styles.main}>
    <HeaderWithRouter />
    <TicketsPage {...props} />
  </div>
);

export default AdminTickets;
