export const validateEmail = email => {
  const validate = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validate.test(email);
};

export const validatePass = pass => {
  return pass.length >= 6;
};

export const emailOnFocus = (email, setEmailError) => {
  if (email !== '' && !validateEmail(email)) {
    setEmailError({ err: 'Email is not Valid!' });
  } else {
    setEmailError({ err: '' });
  }
};

export const passOnFocus = (password, setPassError) => {
  if (password !== '' && !validatePass(password)) {
    setPassError({ err: 'Password is not Valid! Minimum 8 characters.' });
  } else {
    setPassError({ err: '' });
  }
};
