import React, { useState, useEffect } from 'react';

import {
  Input,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

import styles from '../../../Forms.scss';

const ReasonForRequestStep = ({ setForm, formData, navigation, step }) => {
  const { reasonForRequest } = formData;
  const [isValid, setIsValid] = useState(false);



  KeyboardNav(step, isValid, navigation);

  useEffect(() => {
    if (reasonForRequest !== '') {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [formData, isValid]);
  return (
    <>
      <h4>
        <span>8</span> Reason for Request *
      </h4>
      <Input
        name="reasonForRequest"
        placeholder="Type your answer here …"
        type="textarea"
        value={reasonForRequest}
        onChange={setForm}
      />
      <Navigation navigation={navigation} isValid={isValid} />
    </>
  );
};

export default ReasonForRequestStep;
