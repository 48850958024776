import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import cx from 'classnames';
import Loader from 'react-loader-spinner';
import { AuthContext } from '../../../contexts/AuthContext';
import { FetchApiContext } from '../../../contexts/FetchApiContext';
import { pageContext } from '../../../contexts/PageContext';
import * as ROUTES from '../../../constants/routes';
import FilterNav from '../../../components/FilterNav/FilterNav';
import { exportCsv } from '../../../../helpers/exportCsv';
import { BoxRow, BoxContainer } from '../../../components/shared';
import Dropdown from '../../../components/shared/Form/Dropdown';

import styles from './Tickets.scss';

const ALL_TICKETS = gql`
  query AllTickets {
    allTickets(limit: 0) {
      id
      employeeName
      employeeEmail
      reqType
      customReqType
      numberOfTickets
      seatingPreference
      notes
      reasonForRequest
      delivery
      recipientName
      recipientEmail
      recipientPhone
      recipientCompany
      status
      createdAt
      accountNum
      games {
        id
        abbr
      }
    }
  }
`;

const TicketsPage = props => {
  const { history } = props;
  const auth = React.useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [exportUrl, setExportUrl] = useState();
  const [exportPendingUrl, setExportPendingUrl] = useState();
  const [exportApprovedUrl, setExportApprovedUrl] = useState();
  const [exportFulfilledUrl, setExportFulfilledUrl] = useState();
  const [ticketsList, setTicketsList] = useState();
  const [ticketsStatus, setTicketsStatus] = useState();
  const [fetchApi, setFetchApi] = useState(true);
  const [filterStatus, setFilterStatus] = useState({
    ticketType: 'allTicketTypes',
    gameDate: '',
  });
  const [allTicketsFetch] = useLazyQuery(ALL_TICKETS, {
    fetchPolicy: 'network-only',
    onCompleted: res => {
      createStatusArrays(res.allTickets);
      setLoading(false);
    },
    onError: err => {
      console.log(err);
      setLoading(false);
    },
  });
  const [loadMore, setLoadMore] = useState({
    pending: 5,
    approved: 5,
    fulfilled: 5,
  });

  const createStatusArrays = async tickets => {
    setTicketsList(tickets);

    const pendingItems = [];
    const approvedItems = [];
    const fulfilledItems = [];
    let filteredTickets = [];

    switch (filterStatus.ticketType) {
      case 'allTicketTypes':
        filteredTickets = await tickets;
        break;
      case 'charityCommunityRelations':
        filteredTickets = await ticketsList.filter(item =>
          item.reqType.includes('charityCommunityRelations'),
        );
        break;
      case 'formerEmployees':
        filteredTickets = await ticketsList.filter(item =>
          item.reqType.includes('formerEmployees'),
        );
        break;
      case 'media':
        filteredTickets = await ticketsList.filter(item =>
          item.reqType.includes('media'),
        );
        break;
      case 'gamedayWorkers':
        filteredTickets = await ticketsList.filter(item =>
          item.reqType.includes('gamedayWorkers'),
        );
        break;
      case 'localDignitariesVIPs':
        filteredTickets = await ticketsList.filter(item =>
          item.reqType.includes('localDignitariesVIPs'),
        );
        break;
      case 'mediaGuests':
        filteredTickets = await ticketsList.filter(item =>
          item.reqType.includes('mediaGuests'),
        );
        break;
      case 'nflEmployees':
        filteredTickets = await ticketsList.filter(item =>
          item.reqType.includes('nflEmployees'),
        );
        break;
      case 'promotions':
        filteredTickets = await ticketsList.filter(item =>
          item.reqType.includes('promotions'),
        );
        break;
      case 'radioTvTrade':
        filteredTickets = await ticketsList.filter(item =>
          item.reqType.includes('radioTvTrade'),
        );
        break;
      case 'corporatePartnershipObligation':
        filteredTickets = await ticketsList.filter(item =>
          item.reqType.includes('corporatePartnershipObligation'),
        );
        break;
      case 'corporatePartnershipTrade':
        filteredTickets = await ticketsList.filter(item =>
          item.reqType.includes('corporatePartnershipTrade'),
        );
        break;
      case 'suiteTrade':
        filteredTickets = await ticketsList.filter(item =>
          item.reqType.includes('suiteTrade'),
        );
        break;
      case 'stadiumLeaseRequirement':
        filteredTickets = await ticketsList.filter(item =>
          item.reqType.includes('stadiumLeaseRequirement'),
        );
        break;
      case 'ticketingProspect':
        filteredTickets = await ticketsList.filter(item =>
          item.reqType.includes('ticketingProspect'),
        );
        break;
      case 'vendorContractor':
        filteredTickets = await ticketsList.filter(item =>
          item.reqType.includes('vendorContractor'),
        );
        break;
      case 'visitingTeam':
        filteredTickets = await ticketsList.filter(item =>
          item.reqType.includes('visitingTeam'),
        );
        break;
      case 'youthGroup':
        filteredTickets = await ticketsList.filter(item =>
          item.reqType.includes('youthGroup'),
        );
        break;
      case 'customTicketType':
        filteredTickets = await ticketsList.filter(
          item => item.customReqType !== '',
        );
        break;
      default:
        console.log('No tickets!');
    }

    switch (filterStatus.gameDate) {
      case '':
        filteredTickets = await filteredTickets;
        break;

      case 'NYJ':
        filteredTickets = filteredTickets.filter(item =>
          item.games.some(e => e.abbr === 'NYJ'),
        );
        break;

      case 'BUF':
        filteredTickets = filteredTickets.filter(item =>
          item.games.some(e => e.abbr === 'BUF'),
        );
        break;

      case 'CHI':
        filteredTickets = filteredTickets.filter(item =>
          item.games.some(e => e.abbr === 'CHI'),
        );
        break;

      case 'NO':
        filteredTickets = filteredTickets.filter(item =>
          item.games.some(e => e.abbr === 'NO'),
        );
        break;

      case 'IND':
        filteredTickets = filteredTickets.filter(item =>
          item.games.some(e => e.abbr === 'IND'),
        );
        break;

      case 'WSH':
        filteredTickets = filteredTickets.filter(item =>
          item.games.some(e => e.abbr === 'WSH'),
        );
        break;

      case 'HOU':
        filteredTickets = filteredTickets.filter(item =>
          item.games.some(e => e.abbr === 'HOU'),
        );
        break;

      case 'GB':
        filteredTickets = filteredTickets.filter(item =>
          item.games.some(e => e.abbr === 'GB'),
        );
        break;

      case 'TB':
        filteredTickets = filteredTickets.filter(item =>
          item.games.some(e => e.abbr === 'TB'),
        );
        break;

      case 'MIN':
        filteredTickets = filteredTickets.filter(item =>
          item.games.some(e => e.abbr === 'MIN'),
        );
        break;

      default:
        console.log('No tickets!');
    }

    exportCsv('tickets', filteredTickets).then(res => setExportUrl(res));
    exportCsv('tickets', filteredTickets, 'pending').then(res =>
      setExportPendingUrl(res),
    );
    exportCsv('tickets', filteredTickets, 'approved').then(res =>
      setExportApprovedUrl(res),
    );
    exportCsv('tickets', filteredTickets, 'fulfilled').then(res =>
      setExportFulfilledUrl(res),
    );
    await filteredTickets.map(item => {
      const { status } = item;
      switch (status) {
        case 'pending':
          pendingItems.push(item);
          break;
        case 'approved':
          approvedItems.push(item);
          break;
        case 'fulfilled':
          fulfilledItems.push(item);
          break;
        default:
          console.log('No status found!');
      }
      return false;
    });
    setTicketsStatus({
      pending: pendingItems,
      approved: approvedItems,
      fulfilled: fulfilledItems,
    });
  };

  const filterArray = value => {
    setFilterStatus({
      ...filterStatus,
      ticketType: value,
    });
    fetchApiHandler();
  };

  const fetchApiHandler = () => {
    setFetchApi(true);
    setLoading(true);
    allTicketsFetch();
  };

  useEffect(() => {
    if (!auth.token) {
      const jwtAuth = JSON.parse(localStorage.getItem('jwtAuth'));
      !jwtAuth ? history.push(ROUTES.LOGIN) : null;
    }
    if (auth.token && fetchApi) {
      setLoading(true);
      allTicketsFetch();
    }
  }, [auth, ticketsList, filterStatus]);

  return (
    <>
      <FilterNav
        setFilterStatus={setFilterStatus}
        filterStatus={filterStatus}
        fetchApiHandler={fetchApiHandler}
      />
      <div className={styles.body}>
        <div className={styles.filter}>
          <div className={styles.filter__type}>
            <div className={styles.filter__type__item}>
              <Dropdown
                onChange={item => filterArray(item.target.value)}
                page="tickets"
              />
            </div>
          </div>
          <button
            className={styles.btn__filter}
            onClick={() => fetchApiHandler()}
          >
            Refresh
          </button>
          <a
            href={exportUrl}
            download={`comp-tickets-${new Date().getFullYear()}-${new Date().getMonth() +
              1}-${new Date().getDate()}-${new Date().getHours()}_${new Date().getMinutes()}.csv`}
          >
            <button className={styles.btn__filter}>Export</button>
          </a>
        </div>
        <pageContext.Provider value="tickets">
          {loading ? (
            <div className={styles.loader}>
              <Loader type="Grid" color="#1192e2" height={40} width={40} />
            </div>
          ) : (
            <FetchApiContext.Provider value={fetchApiHandler}>
              <BoxContainer title="Pending" exportUrl={exportPendingUrl}>
                {ticketsStatus &&
                  ticketsStatus.pending.map((item, i) => {
                    return i < loadMore.pending ? (
                      <BoxRow item={item} key={item.id} />
                    ) : null;
                  })}
                {ticketsStatus &&
                  loadMore.pending < ticketsStatus.pending.length && (
                    <button
                      onClick={() =>
                        setLoadMore({
                          ...loadMore,
                          pending: loadMore.pending + 5,
                        })
                      }
                      className={cx(
                        styles.load_more,
                        styles['load_more--pending'],
                      )}
                    >
                      Load More +
                    </button>
                  )}
                {ticketsStatus && ticketsStatus.pending.length <= 0 && (
                  <p className={styles.no_item}>No Request</p>
                )}
              </BoxContainer>
              <BoxContainer title="Approved" exportUrl={exportApprovedUrl}>
                {ticketsStatus &&
                  ticketsStatus.approved.map((item, i) => {
                    return i < loadMore.approved ? (
                      <BoxRow item={item} key={item.id} />
                    ) : null;
                  })}
                {ticketsStatus &&
                  loadMore.approved < ticketsStatus.approved.length && (
                    <button
                      onClick={() =>
                        setLoadMore({
                          ...loadMore,
                          approved: loadMore.approved + 5,
                        })
                      }
                      className={cx(
                        styles.load_more,
                        styles['load_more--pending'],
                      )}
                    >
                      Load More +
                    </button>
                  )}
                {ticketsStatus && ticketsStatus.approved.length <= 0 && (
                  <p className={styles.no_item}>No Request</p>
                )}
              </BoxContainer>
              <BoxContainer title="Fulfilled" exportUrl={exportFulfilledUrl}>
                {ticketsStatus &&
                  ticketsStatus.fulfilled.map((item, i) => {
                    return i < loadMore.fulfilled ? (
                      <BoxRow item={item} key={item.id} />
                    ) : null;
                  })}
                {ticketsStatus &&
                  loadMore.fulfilled < ticketsStatus.fulfilled.length && (
                    <button
                      onClick={() =>
                        setLoadMore({
                          ...loadMore,
                          fulfilled: loadMore.fulfilled + 5,
                        })
                      }
                      className={cx(
                        styles.load_more,
                        styles['load_more--pending'],
                      )}
                    >
                      Load More +
                    </button>
                  )}
                {ticketsStatus && ticketsStatus.fulfilled.length <= 0 && (
                  <p className={styles.no_item}>No Request</p>
                )}
              </BoxContainer>
            </FetchApiContext.Provider>
          )}
        </pageContext.Provider>
      </div>
    </>
  );
};

export default TicketsPage;
