import React, { useEffect, useState, createRef } from 'react';
import cx from 'classnames';

import {
  Input,
  Radio,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';
import { credsTypeListExt } from '../../../../../../data/credsTypeListExt';

import styles from '../../../Forms.scss';
import stylesForm from '../../../../../components/shared/Form/Form.scss';

const ReqTypeExtStep = ({ setForm, formData, navigation, step }) => {
  const { reqTypeExt, customReqType } = formData;
  const { next, previous } = navigation;
  const [isValid, setIsValid] = useState(false);
  function hiddenRadioHandler() {
    hiddencustomReq.current ? hiddencustomReq.current.click() : null;
  }
  const hiddencustomReq = createRef();
  const custominput = createRef();
  KeyboardNav(step, isValid, navigation);

  useEffect(() => {
    if (reqTypeExt !== '') {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    custominput.current.addEventListener('focus', hiddenRadioHandler);
    return () => {
      custominput.current
        ? custominput.current.removeEventListener('focus', hiddenRadioHandler)
        : null;
    };
  }, [formData, isValid]);

  return (
    <div>
      <h4>
        <span>16</span> Type of Credential Requested *
      </h4>

      <div className={styles.form__multiple}>
        {credsTypeListExt.map(typeItem => {
          const { id, label } = typeItem;

          return (
            <Radio
              id={id}
              key={id}
              label={label}
              name="reqTypeExt"
              value={id}
              checked={reqTypeExt === id}
              onChange={setForm}
              type="radio"
            />
          );
        })}
        <input
          id="hiddencustomReq"
          key="hiddencustomReq"
          label="hiddencustomReq"
          name="reqTypeExt"
          className={stylesForm.radio__input}
          value="customReq"
          onChange={setForm}
          type="radio"
          ref={hiddencustomReq}
        />

        <label
          htmlFor="customReqType"
          className={cx(stylesForm.input, stylesForm.input__select_item)}
        >
          <input
            type="text"
            placeholder=" "
            id="customReqType"
            name="customReqType"
            onChange={setForm}
            value={customReqType}
            ref={custominput}
          />
          <span className={stylesForm.label}>
            Type here for other type of request ...
          </span>
        </label>
      </div>

      <Navigation navigation={navigation} isValid={isValid} />
    </div>
  );
};

export default ReqTypeExtStep;
